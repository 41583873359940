import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Subject, throwError, BehaviorSubject } from 'rxjs';
import { Slot } from './slots.model';
import { tap, map, catchError } from 'rxjs/operators';
import { ConstantsService } from '../shared/services/constants.service';

export interface ResponseSlot {
  'slot': Slot,
}

export interface ResponseBookedSlot {
  slot: {
    id: string,
    time_id: 7,
    track_id: 4,
    application_id: 17,
    is_active: 1,
    created: string,
    modified: string,
    track: {
      id: 4,
      slot_id: 3,
      name: string,
      created: string,
      modfiied: string,
      slot:{}
    }
  }
}

@Injectable({
  providedIn: 'root'
})


export class SlotsService {

  slotSubject = new BehaviorSubject<Slot>(null);
  private slot: Slot;

  availableSlotSubject = new BehaviorSubject<Slot>(null);
  private availableSlot: Slot;

  bookedSlotSubject = new BehaviorSubject<Slot>(null);
  private bookedSlot: Slot;



  baseUrl = this.constantService.baseAppUrl + 'slots/';

  constructor(
    private http: HttpClient,
    private constantService: ConstantsService) { }

  add(newSlot) {
    const url = this.baseUrl + 'add' + '.json';
    return this.http
      .post<ResponseSlot>(url, newSlot)
      .pipe(
        tap(data => {
          this.setSlot(data.slot);
        }),
        catchError(this.handleError),
      );
  }

  getSlot(id,showAll) {
    let url;
    if(showAll){
      url = this.constantService.baseAppUrl + 'slots/view/' + id +'/'+showAll+'.json';
    }else{
      url = this.constantService.baseAppUrl + 'slots/view/' + id +'.json';
    }
    
    return this.http
      .get<ResponseSlot>(url)
      .pipe(
        tap(data => {
          this.setSlot(data.slot);
        })
      );
  }

  edit(slot) {
    const url = this.baseUrl + 'edit/' + slot.id + '.json';
    return this.http
      .post<ResponseSlot>(url, slot)
      .pipe(
        tap(data => {
          this.setSlot(data.slot);
        }),
        catchError(this.handleError),
      );
  }

  private setSlot(slotData: Slot) {
    this.slot = slotData;
    this.slotSubject.next(slotData);
  }

  private handleError(data: HttpErrorResponse) {
    return throwError(data.error.message);
  }

  deleteTrack(slotId,trackId){
    const url = this.constantService.baseAppUrl + 'tracks/delete/' + trackId + '/' +slotId+ '.json'
    return this.http
      .post<ResponseSlot>(url, trackId)
      .pipe(
        tap(data => {
          this.setSlot(data.slot);
        }),
        catchError(this.handleError),
      );
  }

  getAvailableSlot(id) {
    const url = this.baseUrl + 'add' + '.json';
    return this.http
      .get<ResponseSlot>(
        this.constantService.baseAppUrl + 'slots/getTracksTimes/' + id + '.json'
      )
      .pipe(
        tap(data => {
          this.setAvailableSlot(data.slot);
        })
      );
  }

  private setAvailableSlot(slotData: Slot) {
    this.availableSlot = slotData;
    this.availableSlotSubject.next(slotData);
  }

  bookSlot(slotId, timeTrackId, appId,) {
    const url = this.baseUrl + 'applicationPitchSlot.json';

    const slot = {
      slot_id: slotId,
      times_tracks_id: timeTrackId,
      application_id: appId
    }
    return this.http
      .post<ResponseSlot>(url, slot)
      .pipe(
        tap(data => {
          this.setAvailableSlot(data.slot);
        }),
        catchError(this.handleError),
      );
  }

  getBookedSlotInfo(appId) {
    const url = this.baseUrl + 'getBookedSlot/' + appId + '.json';
    return this.http
      .get<ResponseBookedSlot>(this.baseUrl + 'getBookedSlot/' + appId + '.json')
      .pipe(
        tap(data => {
          this.setBookedSlotInfot(data.slot);
        })
      );
  }

  private setBookedSlotInfot(slotData) {
    this.bookedSlot = slotData;
    this.bookedSlotSubject.next(slotData);
  }

  sendResult(result,slotId) {
    const url = this.baseUrl + 'sendResult/'+slotId+'.json';
    return this.http
      .post<ResponseSlot>(url, result)
      .pipe(
        tap(data => {
          this.setSlot(data.slot);
        }),
        catchError(this.handleError),
      );
  }

  getSlotDates(){
		return this.http.get<any>(
			this.constantService.baseAppUrl + 'slots/getSlotDates.json',
			).pipe(
			catchError(this.handleError),
			tap(res => {
				return this.handleResponse(res);
			})
		)
	}

	private handleResponse(res:any){
		return res.data;
	}

}
