
import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse,HttpResponse } from '@angular/common/http';
import { Subject,throwError } from 'rxjs';
import { tap, map,catchError } from 'rxjs/operators';
import { ConstantsService } from '../services/constants.service';

export interface Country {
  id:string,
  num_code:string,
  en_short_name:string,
  nationality:string
}

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
		private http:HttpClient,
		private constantService: ConstantsService) {

	}

	getCountries(){
		return this.http.get<any>(
			this.constantService.baseAppUrl + 'countries/getCountries.json',
			).pipe(
			catchError(this.handleError),
			tap(res => {
				return this.handleResponse(res);
			})
		)
	}

	private handleResponse(res:any){
		return res.data;
	}

	private handleError(data: HttpErrorResponse) {
		return throwError(data.error.message);
	}
}
