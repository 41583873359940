import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import Swal from 'sweetalert2';
import { SlotsService } from '../slots.service';
import { ConstantsService } from '../../shared/services/constants.service';
import { Subscription } from 'rxjs';
import { TimesService } from '../../times/times.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Slot } from '../slots.model';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },

};

@Component({
  selector: 'app-slot-view',
  templateUrl: './slot-view.component.html',
  styleUrls: ['./slot-view.component.sass'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true } }
  ]
})
export class SlotViewComponent implements OnInit {

  isLoading = true;
  isSaving = false;
  slotId;
  slot: Slot;
  isActive = false;
  count = 0;

  getTimesSubscription: Subscription;
  defaultTimes = []
  getSlotSubscription: Subscription;
  slotSubscription: Subscription;


  // checkbox
  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
  sendResult = []


  constructor(
    private slotsService: SlotsService,
    private timesService: TimesService,
    private route: ActivatedRoute,
    private constService: ConstantsService,
    private router: Router) { }

  ngOnInit() {

    this.route.params.subscribe(
      (params: Params) => this.slotId = params['id']
    );

    this.getSlotSubscription = this.slotsService.getSlot(this.slotId,false).subscribe(() => {
      this.generateSlot();
    })


  }

  private generateSlot() {

    this.slotSubscription = this.slotsService.slotSubject
      .subscribe(
        slot => {
          this.getSlotSubscription.unsubscribe();

          this.slot = slot;

          if (slot.is_active === 1) {
            this.isActive = true;
          }

          this.isLoading = false;


        })
  }



  onViewApp(appId) {
    this.router.navigate(['/applications/' + appId + '/view'])
  }

  showOptions($e, app_id, theResult) {
    if ($e.checked) {
      let result = {
        id: app_id,
        result: theResult
      }
      this.sendResult.push(result);
    } else {
      this.sendResult.splice(this.sendResult.findIndex(item => item.id === app_id), 1)
    }
  }

  onSendResult() {
    if (this.sendResult.length <= 0) {
      Swal.fire({
        title: "Uh Oh!",
        text: "Please select an application.",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "OK"
      })
    } else {
      Swal.fire({
        title: "Hang on!",
        text: "Are you sure to send the results?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Yes, I'm sure"
      }).then(result => {
        if (result.value) {
          this.isSaving = true;
          this.slotsService.sendResult(this.sendResult,this.slotId).subscribe(
            (response: any) => {
              Swal.fire({
                title: "Success",
                text: "The result will be sent to the Applicants",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: "OK",
              })
              this.isSaving = false;
            },
            errorMessage => {
              Swal.fire({
                title: "Uh Oh!",
                text: errorMessage,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: "OK",
              })
              this.isSaving = false;
            }
          )
        }
      });
    }


  }

}
