import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, ValidatorFn, ValidationErrors } from '@angular/forms';

import { startWith, map } from 'rxjs/operators';

import { Subscription, Observable } from 'rxjs';
import Swal from 'sweetalert2';

import { User } from '../user.model';

import { State } from '../../shared/state/state.model';
import { Role } from '../../shared/role/role.model';
import { RaceService } from '../../shared/race/race.service';
import { RoleService } from '../../shared/role/role.service';
import { StateService } from '../../shared/state/state.service';
import { UserService } from '../user.service';
import { Race } from 'src/app/shared/race/race.model';
import { Country, CountryService } from 'src/app/shared/country/country.service';


export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  if (formGroup.get('password').value === formGroup.get('cpassword').value)
    return null;
  else
    return { passwordMismatch: true };
};

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.sass']
})
export class UserEditComponent implements OnInit {
  isLoading = true;
  isSaving = false;
  editUserForm: FormGroup;
  rolesList = new FormArray([]);
  identityTypeId = null;
  raceId = null;
  user = null;
  success: string = null;
  error: string = null;
  isRoleSelected = true;

  public userSub: Subscription;
  public userDetails = null;
  public proSub: Subscription;

  public races: Race[];
  public countries: Country[];
  filteredRaceOptions: Observable<Race[]>;
  public states: State[];
  getRolesSubscription: Subscription;
  public defaultRoles = [];
  minPw = 8;
  userId: string;

  constructor(
    private raceService: RaceService,
    private stateService: StateService,
    private roleService: RoleService,
    private countryService:CountryService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isLoading = true;

    this.route.params.subscribe(
      (params: Params) => this.userId = params['id']
    );

    this.userService.fetchUser(this.userId).subscribe()
    this.createEditUserForm();
  }

  createEditUserForm() {
    this.editUserForm = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      email: new FormControl(null, [Validators.required, Validators.email, Validators.minLength(5)]),
      nationality: new FormControl(null, [Validators.required]),
      identity_type_id: new FormControl(null, [Validators.required]),
      identity_no: new FormControl(null, [Validators.required]),
      race_id: new FormControl(null, [Validators.required]),
      race: new FormControl(null),
      gender: new FormControl(null),
      phone: new FormControl(null, [Validators.required]),
      alt_phone: new FormControl(null),
      is_bumiputera: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      postcode: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      state_id: new FormControl(null, [Validators.required]),
      users_roles: this.rolesList,
      password: new FormControl(null, [Validators.minLength(this.minPw)]),
      cpassword: new FormControl(null)
    }, passwordMatchValidator);

    this.raceService.getRaces().subscribe(
      res => {
        this.races = res.data;
        this.filteredRaceOptions = this.editUserForm.controls.race.valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value.name)),
          map(name => (name ? this._filterRace(name) : this.races.slice()))
        );
      }
    );

    this.countryService.getCountries().subscribe(
      res => {
        this.countries = res.data;
      }
    );

    this.stateService.getStates().subscribe(
      res => {
        this.states = res.data;
      }
    );

    this.proSub = this.userService.userChanged.subscribe(
      pro => {
        this.editUserForm.get('name').setValue(pro.name ?? null);
        this.editUserForm.get('email').setValue(pro.email ?? null);
        this.editUserForm.get('nationality').setValue(pro.nationality ?? null);
        this.editUserForm.get('identity_type_id').setValue(pro.identity_type_id ?? null);
        this.editUserForm.get('identity_no').setValue(pro.identity_no ?? null);

        this.editUserForm.get('race_id').setValue(pro.race_id ?? null);
        this.editUserForm.get('is_bumiputera').setValue(pro.is_bumiputera ?? null);

        this.editUserForm.get('phone').setValue(pro.phone ?? null);
        this.editUserForm.get('gender').setValue(pro.gender ?? null);
        this.editUserForm.get('address').setValue(pro.address ?? null);
        this.editUserForm.get('postcode').setValue(pro.postcode ?? null);
        this.editUserForm.get('state_id').setValue(pro.state_id ?? null);

        this.createUsersRolesForm(pro.roles)

        this.isLoading = false
      });
  }

  private createUsersRolesForm(roles) {
    this.rolesList.clear()
    roles.forEach(role => {
      this.rolesList.push(
        new FormGroup({
          id: new FormControl(role.id),
          name: new FormControl(role.name),
          is_selected: new FormControl(role.is_selected),
        })
      )
    })

    this.isLoading = false;
  }

  get password() { return this.editUserForm.get('password'); }
  get cpassword() { return this.editUserForm.get('cpassword'); }
  get f() {
    return this.editUserForm.controls;
  }

  onPasswordInput() {
    if (this.editUserForm.hasError('passwordMismatch'))
      this.cpassword.setErrors([{ 'passwordMismatch': true }]);
    else
      this.cpassword.setErrors(null);
  }



  onIdentityTypeChange(e) {
    this.identityTypeId = e.value;
  }

  // onRaceChange(e) {
  //   this.raceId = e.value;
  //   const raceOther = this.editUserForm.controls.race;
  //   raceOther.patchValue('');

  //   if (this.raceId >= 4) {
  //     raceOther.setValidators([Validators.required])
  //   } else {
  //     raceOther.setErrors(null);
  //   }
  // }

  // displayFn(race: Race): string {
  //   return race && race.name ? race.name : '';
  // }

  private _filterRace(name: string): Race[] {
    const filterValue = name.toLowerCase();
    return this.races.filter(
      option => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  nationaityChange(event) {
    let value = event?.value ?? event;
    if (value) {
      if (value == 'Malaysian') {
        this.editUserForm.addControl('is_bumiputera', new FormControl(null, [Validators.required]));
        this.editUserForm.addControl('race_id', new FormControl(null, [Validators.required]));
        this.editUserForm.removeControl('country_id');
      } else if (value == 'Non-Malaysian') {
        this.editUserForm.removeControl('is_bumiputera');
        this.editUserForm.removeControl('race_id');
        this.editUserForm.addControl('country_id', new FormControl(null, [Validators.required]));
      }
    }
  }

  bumiputraChange(event) {
    this.editUserForm.controls.race_id.reset();
  }

  onSubmit() {
    let rolesIDs = []
    let users_roles = this.editUserForm.get('users_roles').value;
    let data = this.editUserForm.value

    users_roles.forEach(role => {
      if (role.is_selected) {
        rolesIDs.push(role.id)
      }
    });

    data.roles = { _ids: rolesIDs }

    if (rolesIDs.length <= 0) {
      Swal.fire({
        title: 'Uh Oh!!',
        text: "Please Select Role(s).",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        //cancelButtonText: 'No, I am not!',
        confirmButtonText: 'OK'
      })
      this.isRoleSelected = false;
    } else {
      this.isRoleSelected = true;
    }

    if (data.password === "") {
      delete data.password
    }

    if (this.editUserForm.valid && this.isRoleSelected) {
      const result = this.userService.editUser(data,this.userId);
      result.subscribe(
        res => {
          Swal.fire({
            title: 'Congratulation!',
            text: "Your User has been Updated",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            //cancelButtonText: 'No, I am not!',
            confirmButtonText: 'OK'
          }).then(result => {
            this.router.navigate(['/users/' + this.userId + '/view'])
          })
        },
        errorMessage => {
          console.log(errorMessage)
          Swal.fire({
            title: 'Sorry!',
            text: errorMessage,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            //cancelButtonText: 'No, I am not!',
            confirmButtonText: 'OK'
          }).then(result => {
            // if (result.value) {
            //   this.router.navigate(['/user/details'])
            // }
          });
        }
      )
    }
  }

}
