import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject, throwError } from 'rxjs';
import { Race } from './race.model';
import { tap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConstantsService } from '../services/constants.service';


@Injectable({
  providedIn: 'root'
})

export class RaceService {
  private races = new Subject<Race>();
  constructor(
    private http: HttpClient,
    private constantService: ConstantsService) {

  }

  // getRaces(){
  // 	return this.http.get<any>(`${environment.apiUrl}races/getRaces.json`
  // 	).pipe(
  // 	catchError(this.handleError),
  //     	tap(res => {
  //     		return this.handleResponse(res);
  //     	})
  //   )
  // }

  getRaces() {
    return this.http.get<any>(
      this.constantService.baseAppUrl + 'races/getRaces.json',
    ).pipe(
      catchError(this.handleError),
      tap(res => {
        return this.handleResponse(res);
      })
    )
  }

  private handleResponse(res: any) {
    return res.data;
  }

  private handleError(data: HttpErrorResponse) {
    return throwError(data.error.message);
  }
}
