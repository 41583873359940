import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from "../shared/components/components.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMaskModule } from 'ngx-mask';
import { DataTablesModule } from 'angular-datatables';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SlotsRoutingModule } from './slots-routing.module';
import { AddComponent } from './add/add.component';
import { SlotEditComponent } from './slot-edit/slot-edit.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SlotListComponent } from './slot-list/slot-list.component';
import { SlotViewComponent } from './slot-view/slot-view.component';
import { SlotBookingComponent } from './slot-booking/slot-booking.component';
import { SlotPanelListComponent } from './slot-panel-list/slot-panel-list.component';
import { SlotPanelViewComponent } from './slot-panel-view/slot-panel-view.component';
// import { SlotEditComponent } from './slot-edit/slot-edit.component';
// import { SlotViewComponent } from './slot-view/slot-view.component';
// import { SlotBookingComponent } from './slot-booking/slot-booking.component';
// import { SlotPanelListComponent } from './slot-panel-list/slot-panel-list.component';
// import { SlotPanelViewComponent } from './slot-panel-view/slot-panel-view.component';



@NgModule({
  declarations: [
    AddComponent,
    SlotEditComponent,
    SlotListComponent,
    SlotViewComponent,
    SlotBookingComponent,
    SlotPanelListComponent,
    SlotPanelViewComponent,
  ],
  imports: [
    CommonModule,
    SlotsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatCheckboxModule,
    NgxMaskModule,
    DataTablesModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    ComponentsModule,
    DataTablesModule,
    NgxDatatableModule,
  ]
})
export class SlotsModule { }
