import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  // Profile Modules
  {
    path: "/profile",
    title: "Profile",
    moduleName: "profile",
    iconType: "material-icons-two-tone",
    icon: "person",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [
      {
        path: "/profile/overview",
        title: "Overview",
        moduleName: "overview",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/profile/settings",
        title: "Update Profile",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // Application Module
  {
    path: "",
    title: "Applications",
    moduleName: "",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Applicant"],
    submenu: [
      {
        path: "/applications/list",
        title: "Application List",
        moduleName: "application-list",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/applications/new/cipspark",
        title: "Apply for CIP SPARK",
        moduleName: "settiapplication-apply-spark",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/applications/new/cipsprint",
        title: "Apply for CIP SPRINT",
        moduleName: "settiapplication-apply-sprint",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // Admin Module
  {
    path: "applications/admin",
    title: "Admin",
    moduleName: "applications-admin",
    iconType: "material-icons-two-tone",
    icon: "accessibility",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "",
        title: "Applications",
        moduleName: "applications-admin",
        iconType: "",
        icon: "",
        class: "ml-sub-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [
          {
            path: "/applications/admin/list/cipspark",
            title: "Application List CIP SPARK",
            moduleName: "applications-admin",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "applications/admin/list/cipsprint",
            title: "Application List CIP SPRINT",
            moduleName: "applications-admin",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
        ],
      },
      {
        path: "",
        title: "Users",
        moduleName: "users-admin",
        iconType: "",
        icon: "",
        class: "ml-sub-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [
          {
            path: "users/list",
            title: "Users List",
            moduleName: "users-admin",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "users/new",
            title: "Add New User",
            moduleName: "users-admin",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
        ],
      },
      {
        path: "",
        title: "Pitch Slot",
        moduleName: "slots",
        iconType: "",
        icon: "",
        class: "ml-sub-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [
          {
            path: "/slots/add",
            title: "Add Slot",
            moduleName: "slots",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "/slots/list/cipspark",
            title: "List of Pitch Slot CIP SPARK",
            moduleName: "slots",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "/slots/list/cipsprint",
            title: "List of Pitch Slot CIP SPRINT",
            moduleName: "slots",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
        ],
      },
    ],
  },
  // HOU EVU Module
  {
    path: "applications/houevu",
    title: "HOU EVU",
    moduleName: "applications-houevu",
    iconType: "material-icons-two-tone",
    icon: "accessibility",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["HOU EVU"],
    submenu: [
      {
        path: "applications/houevu",
        title: "Applications",
        moduleName: "applications-houevu",
        iconType: "",
        icon: "",
        class: "ml-sub-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [
          {
            path: "/applications/houevu/list/cipspark",
            title: "Application List CIP SPARK",
            moduleName: "applications-houevu",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "applications/houevu/list/cipsprint",
            title: "Application List CIP SPRINT",
            moduleName: "applications-houevu",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
        ],
      },
      {
        path: "",
        title: "Applications (Shortlisted)",
        moduleName: "applications-shortlisted-houevu",
        iconType: "",
        icon: "",
        class: "ml-sub-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["HOU EVU"],
        submenu: [
          {
            path: "/applications/houevu/shortlisted/cipspark",
            title: "Application List CIP SPARK",
            moduleName: "applications-shortlisted-houevu",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "applications/houevu/shortlisted/cipsprint",
            title: "Application List CIP SPRINT",
            moduleName: "applications-shortlisted-houevu",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
        ],
      },
      {
        path: "",
        title: "FRC",
        moduleName: "users",
        iconType: "",
        icon: "",
        class: "ml-sub-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [
          {
            path: "rmslots/hou/list",
            title: "FRC List",
            moduleName: "rm",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "rmslots/addslot",
            title: "Add FRC Slot",
            moduleName: "rm",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
        ],
      },
      // {
      //   path: "",
      //   title: "BGIC",
      //   moduleName: "",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-sub-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [
      //     {
      //       path: "amslots/hou/list",
      //       title: "BGIC List",
      //       moduleName: "rm",
      //       iconType: "",
      //       icon: "",
      //       class: "ml-sub-sub-menu",
      //       groupTitle: false,
      //       badge: "",
      //       badgeClass: "",
      //       role: [""],
      //       submenu: [],
      //     },
      //     {
      //       path: "amslots/addslot",
      //       title: "Add BGIC Slot",
      //       moduleName: "rm",
      //       iconType: "",
      //       icon: "",
      //       class: "ml-sub-sub-menu",
      //       groupTitle: false,
      //       badge: "",
      //       badgeClass: "",
      //       role: [""],
      //       submenu: [],
      //     },
      //   ],
      // },
      {
        path: "",
        title: "Pitch Slot",
        moduleName: "slots",
        iconType: "",
        icon: "",
        class: "ml-sub-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [
          {
            path: "/slots/add",
            title: "Add Slot",
            moduleName: "slots",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "/slots/list/cipspark",
            title: "List of Pitch Slot CIP SPARK",
            moduleName: "slots",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "/slots/list/cipsprint",
            title: "List of Pitch Slot CIP SPRINT",
            moduleName: "slots",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
        ],
      },
    ],
  },
  // Pitch Panel Module
  {
    path: "",
    title: "Pitch Panel",
    moduleName: "pitchpanel",
    iconType: "material-icons-two-tone",
    icon: "accessibility",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Pitch Panel"],
    submenu: [
      {
        path: "",
        title: "Applications",
        moduleName: "pitchpanel",
        iconType: "",
        icon: "",
        class: "ml-sub-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [
          {
            path: "slots/panel/list/cipspark",
            title: "Application List CIP SPARK",
            moduleName: "applications",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "slots/panel/list/cipsprint",
            title: "Application List CIP SPRINT",
            moduleName: "applications",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
        ],
      },
    ],
  },
  // FRM Panel Module
  {
    path: "",
    title: "FRC Panel",
    moduleName: "rmslot",
    iconType: "material-icons-two-tone",
    icon: "accessibility",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["RM Panel"],
    submenu: [
      {
        path: "",
        title: "FRC Slot",
        moduleName: "rmslot",
        iconType: "",
        icon: "",
        class: "ml-sub-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [
          {
            path: "rmslots/panel/list",
            title: "FRC Slot List",
            moduleName: "rmslot",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
        ],
      },
    ],
  },
  // IM Module
  {
    path: "applications/im",
    title: "IM",
    moduleName: "applications-im",
    iconType: "material-icons-two-tone",
    icon: "accessibility",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["IM"],
    submenu: [
      {
        path: "",
        title: "Applications",
        moduleName: "applications-im",
        iconType: "",
        icon: "",
        class: "ml-sub-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [
          {
            path: "/applications/im/shortlisted/cipspark",
            title: "Application List CIP SPARK",
            moduleName: "applications-im",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "applications/im/shortlisted/cipsprint",
            title: "Application List CIP SPRINT",
            moduleName: "applications-im",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
        ],
      },
    ],
  },
  // Documents For Review Module
  {
    path: "applications/reviews",
    title: "Document For Review",
    moduleName: "applications-review",
    iconType: "material-icons-two-tone",
    icon: "accessibility",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["IM",'HOU EVU'],
    submenu: [
      {
        path: "/applications/review/bplist",
        title: "Business Proposal (Sprint)",
        moduleName: "application-review",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/applications/review/imrecolist",
        title: "Analyst Recommendation",
        moduleName: "application-review",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/applications/review/svlist",
        title: "Site Visit",
        moduleName: "application-review",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/applications/review/isrlist",
        title: "Investment Summary Report (Spark)",
        moduleName: "application-review",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ]
  },
];
