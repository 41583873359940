<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">Slots</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="">
                <i class="fas fa-home"></i> Home</a>
            </li>
            <li class="breadcrumb-item active">Pitch Slot List</li>
            <li class="breadcrumb-item active li-loading" *ngIf="isLoading">
              <div class="loading-spinner">
                <div class="preloader pl-size-xs">
                  <div class="spinner-layer pl-indigo">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
                <p class="preloader-text"> Loading...</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="card">
            <div class="header">
              <img src="assets/images/logo-cipignite1.png" alt="" *ngIf="appType == 'cipignite1'" />
              <img src="assets/images/logo-cipignite2.png" alt="" *ngIf="appType == 'cipignite2'" />
              <h2><strong>Slots List</strong></h2>
            </div>
            <div class="body">
              <div class="row">
                <div class="col">
                  <table datatable [dtOptions]="dtOptions" class="table" style="width: 100%;">
                    <thead>
                      <tr>
                        <th style="width: 20px;">#</th>
                        <th>Batch No.</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th style="text-align: center;">Action</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="slots?.length != 0">
                      <tr *ngFor="let slot of slots | keyvalue">
                        <th>{{ +slot.key + 1 }}</th>
                        <td>{{ slot.value.batch_no }}</td>
                        <td>{{slot.value.date | date:'dd/MM/y'}}</td>
                        <td>{{ slot.value.is_active }}</td>

                        <td style="text-align: center;">
                          <button mat-mini-fab class="btn btn-primary mr-3" (click)="onViewSlot(slot.value.id)"
                            matTooltip="View Slot List" matTooltipPosition="above">
                            <i class="material-icons">perm_contact_calendar</i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="slots?.length == 0">
                      <tr>
                        <td colspan="3" class="no-data-available">No data!</td>
                      </tr>
                    <tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
