import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Subject, throwError, BehaviorSubject } from 'rxjs';
import { Application, ApplicationList, Applicant } from './application.model';
import { tap, map, catchError } from 'rxjs/operators';
import { ConstantsService } from '../shared/services/constants.service';

export interface ResponseViewApplication {
  'application': Application,
  'primary_applicant': Applicant,
  'secondary_applicant': Applicant
}

export interface InvResponseData {
  'secondary_applicant': Applicant
}

@Injectable({
  providedIn: 'root'
})


export class ApplicationService {

  appListsubject = new Subject<ApplicationList>();
  private appList: ApplicationList;

  appSubject = new BehaviorSubject<Application>(null);
  private app: Application;

  priAppSubject = new BehaviorSubject<Applicant>(null);
  private priApp: Applicant;

  secAppSubject = new BehaviorSubject<Applicant>(null);
  private secApp: Applicant;


  constructor(
    private http: HttpClient,
    private constantService: ConstantsService) { }

  index(userId) {
    return this.http
      .get<ApplicationList>(
        this.constantService.baseAppUrl + 'applications/index/' + userId + '.json'
      )
      .pipe(
        tap(appList => {
          this.setAppList(appList);
        })
      );
  }

  private setAppList(appListData: ApplicationList) {
    this.appList = appListData;
    this.appListsubject.next(appListData);
  }

  update(id, profile) {
    let baseUrl = this.constantService.baseAppUrl + 'applications/';
    let url = id ? baseUrl + 'edit/' + id + '.json' : baseUrl + 'add' + '.json';
    return this.http
      .post<ResponseViewApplication>(url, profile)
      .pipe(
        tap(data => {
          this.setApp(data.application);
          // this.setPriApp(data.primary_applicant);
          // this.setSecApp(data.secondary_applicant);
        }),
        catchError(this.handleError),
      );
  }

  fetch(id) {
    return this.http
      .get<ResponseViewApplication>(
        this.constantService.baseAppUrl + 'applications/view/' + id + '.json'
      )
      .pipe(
        tap(data => {
          this.setApp(data.application);
          this.setPriApp(data.primary_applicant);
          this.setSecApp(data.secondary_applicant);
        })
      );
  }

  upload(formData) {
    return this.http
      .post<any>(
        this.constantService.baseAppUrl + 'applications/upload.json', formData, {
        reportProgress: true,
        observe: 'events'
      })
  }

  private setApp(appData: Application) {
    this.app = appData;
    this.appSubject.next(appData);
  }

  private setPriApp(priAppData: Applicant) {
    this.priApp = priAppData;
    this.priAppSubject.next(priAppData);
  }

  getPriApp() {
    return this.priApp
  }

  private setSecApp(secAppData: Applicant) {
    this.secApp = secAppData;
    this.secAppSubject.next(secAppData);
  }

  getAppList() {
    return this.appList;
  }

  private handleError(data: HttpErrorResponse) {
    return throwError(data);
  }

  inviteSecApp(email: string, appId: string) {
    return this.http.post<InvResponseData>(
      this.constantService.baseAppUrl + 'users/appInvitation.json',
      {
        email: email,
        application_id: appId,
        link: "https://cipignite.cradle.com.my/#/authentication/signup"
      }
    ).pipe(
      catchError(this.handleError),
      tap(resData => {

        this.setSecApp(resData.secondary_applicant);
      })
    );
  }

  delSecApp(appUserId: number) {
    return this.http.post<InvResponseData>(
      this.constantService.baseAppUrl + 'applications/delSecApp/' + appUserId + '.json',
      {
        id: appUserId
      }).pipe(
        catchError(this.handleError),
        tap(resData => {
          this.setSecApp(resData.secondary_applicant);
        })
      );
  }

  acceptInvitation(appId, userId) {
    return this.http.post<ApplicationList>(
      this.constantService.baseAppUrl + 'applications/acceptInvitation.json',
      {
        application_id: appId,
        user_id: userId
      }
    ).pipe(
      catchError(this.handleError),
      tap(appList => {
        this.setAppList(appList);
      })
    );
  }

  withdrawApp(appId: string) {
    return this.http.post<ApplicationList>(
      this.constantService.baseAppUrl + 'applications/updateStatus.json',
      {
        application_id: appId,
        status_id: 4,
      }
    ).pipe(
      catchError(this.handleError),
      tap(appList => {
        this.setAppList(appList);
      })
    );
  }

  withdrawShorlistedApp(appId: string) {
    return this.http.post<ApplicationList>(
      this.constantService.baseAppUrl + 'applications/updateStatus.json',
      {
        application_id: appId,
        status_id: 28,
      }
    ).pipe(
      catchError(this.handleError),
      tap(appList => {
        this.setAppList(appList);
      })
    );
  }

  acceptApp(appId: string) {
    return this.http.post<ApplicationList>(
      this.constantService.baseAppUrl + 'applications/updateStatus.json',
      {
        application_id: appId,
        status_id: 5,
      }
    ).pipe(
      catchError(this.handleError),
      tap(appList => {
        this.setAppList(appList);
      })
    );
  }

  rejectApp(appId: string, statusId: number, remarks: string, tags: {}) {
    return this.http.post<ApplicationList>(
      this.constantService.baseAppUrl + 'applications/updateStatus.json',
      {
        application_id: appId,
        status_id: statusId,
        application_reject: {
          remarks: remarks,
          tags: tags,
        }

      }
    ).pipe(
      catchError(this.handleError),
      tap(appList => {
        this.setAppList(appList);
      })
    );
  }



  submit(id, application) {
    let baseUrl = this.constantService.baseAppUrl + 'applications/';
    let url = baseUrl + 'submit/' + id + '.json';

    return this.http
      .post<any>(url, application)
      .pipe(
        tap(data => {
          return data;
        }),
        catchError(this.handleError),
      );
  }

  updateRMAppType(appId, rmAppType) {
    const baseUrl = this.constantService.baseAppUrl + 'applications/';
    const url = baseUrl + 'updateRmAppType.json';
    const data = {
      application_id: appId,
      rm_app_type: rmAppType
    }
    return this.http
      .post<ResponseViewApplication>(url, data)
      .pipe(
        tap(data => {
          // this.fetch(appId)
          return data;
          // this.setApp(data.application);
          // this.setPriApp(data.primary_applicant);
          // this.setSecApp(data.secondary_applicant);
        }),
        catchError(this.handleError),
      );
  }

  setIm(appId, imId) {
    const baseUrl = this.constantService.baseAppUrl + 'applications/';
    const url = baseUrl + 'setIM.json';
    const data = {
      application_id: appId,
      im_id: imId
    }
    return this.http
      .post<ResponseViewApplication>(url, data)
      .pipe(
        tap(data => {
          return data;
          //this.setApp(data.application);
          // this.setPriApp(data.primary_applicant);
          // this.setSecApp(data.secondary_applicant);
        }),
        catchError(this.handleError),
      );
  }

  setRm(appId, rmId,type) {
    const baseUrl = this.constantService.baseAppUrl + 'applications/';
    const url = baseUrl + 'setRM.json';
    const data = {
      application_id: appId,
      rm_id: rmId,
      type:type
    }
    return this.http
      .post<ResponseViewApplication>(url, data)
      .pipe(
        tap(data => {
          return data;
        }),
        catchError(this.handleError),
      );
  }

  setAm(appId, amId,type) {
    const baseUrl = this.constantService.baseAppUrl + 'applications/';
    const url = baseUrl + 'setAM.json';
    const data = {
      application_id: appId,
      am_id: amId,
      type:type
    }
    return this.http
      .post<ResponseViewApplication>(url, data)
      .pipe(
        tap(data => {
          return data;
        }),
        catchError(this.handleError),
      );
  }

  setInterviewDate(appId, interviewDate) {
    const baseUrl = this.constantService.baseAppUrl + 'applications/';
    const url = baseUrl + 'setDateInterview.json';
    const data = {
      application_id: appId,
      date_interview: interviewDate
    }
    return this.http
      .post<ResponseViewApplication>(url, data)
      .pipe(
        tap(data => {
          return data;
        }),
        catchError(this.handleError),
      );
  }

  getRMDate() {
    return this.http.get<any>(
      this.constantService.baseAppUrl + 'RecommendationMeetings/getRmList.json',
    ).pipe(
      catchError(this.handleError),
      tap(res => {
        return res.data;
      })
    )
  }

  getAMDate() {
    return this.http.get<any>(
      this.constantService.baseAppUrl + 'ApprovalMeetings/getAmList.json',
    ).pipe(
      catchError(this.handleError),
      tap(res => {
        return res.data;
      })
    )
  }

  getDocsForReview(docs) {
    let getDoc = "";
    if (docs === "svlist") {
      getDoc = "SiteVisits";
    } else if (docs === "imrecolist") {
      getDoc = "ImRecommendations";
    } else if (docs === "bplist") {
      getDoc = "BusinessProposals";
    } else if (docs === "isrlist") {
      getDoc = "InvestmentSummaries";
    }

    return this.http.get<any>(
      this.constantService.baseAppUrl + getDoc + '/index.json',
    ).pipe(
      catchError(this.handleError),
      tap(res => {
        return res.data;
      })
    )
  }

  getAppReport() {
    return this.http.get<any>(
      this.constantService.baseAppUrl + 'applications/reports.json',
    ).pipe(
      catchError(this.handleError),
      tap(res => {
        return res.data;
      })
    )
  }

}
