<section class="content">
  <div class="content-block">
    <div class="block-header">
      <app-breadcrumb title='Pitch Slot' [items]="['Applications']" active_item="Pitch Slot"></app-breadcrumb>
      <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="card">
            <div class="header">
              <img src="assets/images/logo-cip-spark.png" width="20%" alt="" *ngIf="appType == 'cipspark'" />
              <img src="assets/images/logo-cip-sprint.png" width="20%" alt="" *ngIf="appType == 'cipsprint'" />
            </div>
            <div class="body">
              <div class="row">
                <div class="col-sm-12">
                  <p *ngIf="application" class="mt-3"><strong>Application Name : {{application.title}}</strong></p>
                  <p *ngIf="bookedSlot"><strong>You have Booked your Pitch Slot</strong></p>
                  <p *ngIf="bookedSlot">
                    Date : {{bookedSlot.date | date:'dd/MM/y'}}<br>
                    Venue : {{bookedSlot.venue}}<br>
                    Track : {{bookedSlot.track.name}}<br>
                    Time : {{bookedSlot.track.time}}<br>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <table datatable [dtOptions]="dtOptions" class="table" style="width: 100%;">
                    <thead>
                      <tr>
                        <th style="width: 20px;">#</th>
                        <th>Batch No.</th>
                        <th>Date</th>
                        <th style="text-align: center;">Action</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="slots?.length != 0">
                      <tr *ngFor="let slot of slots | keyvalue">
                        <th>{{ +slot.key + 1 }}</th>
                        <td>{{ slot.value.batch_no }}</td>
                        <td>{{slot.value.date | date:'dd/MM/y'}}</td>
                        <td style="text-align: center;">
                          <button mat-mini-fab class="btn btn-primary" (click)="onViewSlots(appID,slot.value.id)"
                            matTooltip="View Slot" matTooltipPosition="above">
                            <i class="material-icons">arrow_forward</i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="slots?.length == 0">
                      <tr>
                        <td colspan="4" class="no-data-available">No Available Slot for the Moment!</td>
                      </tr>
                    <tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isViewSlots">
        <div class="row clearfix" *ngFor="let tracks of availableSlots.tracks; let i = index">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="card">
              <div class="header">
                <h2><strong>{{tracks.name}}</strong></h2>
              </div>
              <div class="body">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <strong>Time Slots</strong>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3"
                        *ngFor="let times_tracks of tracks.times_tracks; let j = index">
                        <mat-radio-button class="example-margin" value="{{times_tracks.id}}"
                          (change)="updateSlotTime(times_tracks.id)">
                          {{times_tracks.time}}
                        </mat-radio-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <button class="pull-right  mr-3" type="button" mat-raised-button color="primary"
                      (click)="bookSlot()">Book
                      Slot</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
