<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">Pitch Slots</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="">
                <i class="fas fa-home"></i> Home</a>
            </li>
            <!-- <li class="breadcrumb-item active">{{ applicationTitle }}</li> -->
            <li class="breadcrumb-item active">Pitch Slot View</li>
            <li class="breadcrumb-item active li-loading" *ngIf="isLoading">
              <div class="loading-spinner">
                <div class="preloader pl-size-xs">
                  <div class="spinner-layer pl-indigo">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
                <p class="preloader-text"> Loading...</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="row clearfix" *ngIf="!isLoading">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="header">
              <h2>Pitch Slot Details</h2>
            </div>
            <div class="body">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                  <img src="assets/images/logo-{{slot.type}}.png" alt="" />
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
                  <strong>Pitch Slot Date</strong>
                  <br>
                  <p class="text-muted">{{slot.date | date:'dd/MM/y'}}</p>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
                  <strong>Batch No.</strong>
                  <br>
                  <p class="text-muted">{{slot.batch_no}}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                  <strong>Venue</strong>
                  <br>
                  <p class="text-muted">{{slot.venue}}</p>
                </div>
              </div>
            </div>
            <div class="footer mb-3">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                <p class="pull-left">This Pitch Slot is currently <span *ngIf="isActive"
                    class="text-green">Active</span>
                  <span *ngIf="!isActive" class="text-red">Not Active</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="slot">
        <div class="row clearfix" *ngFor="let track of slot.tracks; let i = index">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="card">
              <div class="body">
                <strong>Track name</strong>
                <br>
                <p class="text-muted">{{track.name}}</p>
                <br><br>
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Time</th>
                          <th>Application Title</th>
                          <th>Company Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let time of track.times_tracks; let j = index">
                          <tr *ngIf="time.application">
                            <th>{{ +j + 1 }}</th>
                            <td>{{time.time}}</td>
                            <td>{{time.application.title}}</td>
                            <td>{{time.application?.company?.name ?? '-'}}</td>
                            <td>
                              <!-- <button mat-mini-fab class="btn btn-primary" (click)="onViewApp(time.application.id)"
                              matTooltip="View Application" matTooltipPosition="above">
                              <mat-icon>arrow_forward</mat-icon>
                              </button> -->
                              <a mat-mini-fab class="btn btn-primary" href="    /#/applications/{{time.application.id}}/view" target="_blank"
                                matTooltip="View Application" matTooltipPosition="above">
                                <mat-icon>arrow_forward</mat-icon>
                              </a>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="footer">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
