<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">User Details</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="">
                <i class="fas fa-home"></i> Home</a>
            </li>
            <li class="breadcrumb-item bcrumb-2">
              <a href="#" onClick="return false;">Users</a>
            </li>
            <li class="breadcrumb-item active">Details</li>
            <li class="breadcrumb-item active li-loading" *ngIf="isLoading">
              <div class="loading-spinner">
                <div class="preloader pl-size-xs">
                  <div class="spinner-layer pl-indigo">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
                <p class="preloader-text"> Loading...</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="row" *ngIf="!isLoading">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="header">
              <h2>User Details</h2>
            </div>
            <div class="body">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 b-r">
                  <strong>Full Name as per IC</strong>
                  <br>
                  <p class="text-muted">{{proDetails.name}}</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 b-r">
                  <strong>Email</strong>
                  <br>
                  <p class="text-muted">{{proDetails.email}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 b-r">
                  <strong>Nationality</strong>
                  <br>
                  <p class="text-muted">{{proDetails.nationality}}</p>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 b-r">
                  <strong>Identity Type</strong>
                  <br>
                  <p class="text-muted">{{proDetails.identity_type.name}}</p>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 b-r">
                  <strong>Identification No.</strong>
                  <br>
                  <p class="text-muted">{{proDetails.identity_no}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 b-r">
                  <strong>Race</strong>
                  <br>
                  <p class="text-muted">{{proDetails.race.name}}</p>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 b-r">
                  <strong>Phone No.</strong>
                  <br>
                  <p class="text-muted">{{proDetails.phone}}</p>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 b-r">
                  <strong>Alternate Phone No.</strong>
                  <br>
                  <p class="text-muted">{{proDetails.alt_phone}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 b-r">
                  <strong>Full Address as per IC</strong>
                  <br>
                  <p class="text-muted">{{proDetails.address}}</p>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 b-r">
                  <strong>Postcode</strong>
                  <br>
                  <p class="text-muted">{{proDetails.postcode}}</p>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 b-r">
                  <strong>State</strong>
                  <br>
                  <p class="text-muted">{{proDetails.state.name}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 b-r">
                  <strong>Roles</strong>
                  <br>
                  <ol class="standard-ol">
                    <ng-container *ngFor="let role of proDetails.roles">
                      <li *ngIf="role.is_selected">{{role.name}}</li>
                    </ng-container>
                  </ol>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 b-r">
                  <button class="btn btn-primary pull-right" (click)="onEdit()">Edit User</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>