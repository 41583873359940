<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">Edit User</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="">
                <i class="fas fa-home"></i> Home</a>
            </li>
            <li class="breadcrumb-item bcrumb-2">
              <a href="/Users">Users</a>
            </li>
            <li class="breadcrumb-item active">Edit</li>
            <li class="breadcrumb-item active li-loading" *ngIf="isLoading">
              <div class="loading-spinner">
                <div class="preloader pl-size-xs">
                  <div class="spinner-layer pl-indigo">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
                <p class="preloader-text"> Loading...</p>
              </div>
            </li>
            <li class="breadcrumb-item active li-loading" *ngIf="isSaving">
              <div class="loading-spinner">
                <div class="preloader pl-size-xs">
                  <div class="spinner-layer pl-indigo">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
                <p class="preloader-text"> Saving...</p>
              </div>
            </li>
          </ul>
          <div class="alert alert-danger" *ngIf="error">
            <button type="button" (click)="error=null" class="close" data-dismiss="alert"
              aria-hidden="true">&times;</button>
            <strong>Error:: </strong> {{error}}
          </div>
          <div class="alert alert-success" *ngIf="success">
            <button type="button" (click)="success=null" class="close" data-dismiss="alert"
              aria-hidden="true">&times;</button>
            <strong>Success:: </strong> {{success}}
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!isLoading">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="header">
              <h2>Edit User</h2>
            </div>
            <div class="body">

              <form class="m-4" [formGroup]="editUserForm" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                      <mat-label>Full Name as per IC</mat-label>
                      <input matInput formControlName="name" autocomplete="off" required>
                      <mat-error *ngIf="editUserForm.get('name').hasError('required')">
                        Full Name is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                      <mat-label>Email</mat-label>
                      <input matInput formControlName="email" required>
                      <mat-error *ngIf="
                      (editUserForm.get('email').hasError('email')
                      || editUserForm.get('email').hasError('required')) && editUserForm.get('email').touched">
                        Please enter a valid email address
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                      <mat-label>Nationality</mat-label>
                      <mat-select formControlName="nationality" required (selectionChange)="nationaityChange($event)">
                        <mat-option [value]="'Malaysian'">
                          Malaysian
                        </mat-option>
                        <mat-option [value]="'Non-Malaysian'">
                          Non-Malaysian
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="editUserForm.get('nationality').hasError('required')">
                        Select Nationality
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                      <mat-label>Identity Type</mat-label>
                      <mat-select formControlName="identity_type_id" required
                        (selectionChange)="onIdentityTypeChange($event)">
                        <mat-option [value]="1">
                          NRIC
                        </mat-option>
                        <mat-option [value]="2">
                          Passport
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="editUserForm.get('identity_type_id').hasError('required')">
                        Select Identity Type
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
                    <mat-form-field class="example-full-width mb-3" appearance="outline" *ngIf="identityTypeId == '1'">
                      <mat-label>Identification No.</mat-label>
                      <input matInput formControlName="identity_no" placeholder="ex: 000000-00-0000"
                        mask="000000-00-0000" />
                      <mat-error *ngIf="editUserForm.get('identity_no').hasError('required')">
                        Identification No. is required
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-full-width mb-3" appearance="outline" *ngIf="identityTypeId == '2'">
                      <mat-label>Passport No.</mat-label>
                      <input matInput formControlName="identity_no" />
                      <mat-error *ngIf="editUserForm.get('identity_no').hasError('required')">
                        Passport No. is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3"
                    *ngIf="this.editUserForm.controls.nationality.value == 'Non-Malaysian'">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                      <mat-label>Citizenship</mat-label>
                      <mat-select formControlName="country_id" required (selectionChange)="onRaceChange($event)">
                        <ng-container *ngFor="let country of countries">
                          <mat-option [value]="country.id">
                            {{country.nationality + ' (' + country.en_short_name + '}'}}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                      <mat-error *ngIf="editUserForm.get('country_id').hasError('required')">
                        Please select your Citizenship
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3"
                    *ngIf="this.editUserForm.controls.nationality.value == 'Malaysian'">
                    <mat-label>Bumiputera?</mat-label>
                    <br><br>
                    <mat-radio-group formControlName="is_bumiputera" class="example-full-width mb-3" required
                      (change)="bumiputraChange(event)">
                      <mat-radio-button class="example-margin" [value]="1">
                        Yes
                      </mat-radio-button>
                      <mat-radio-button class="example-margin" [value]="0">
                        No
                      </mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="editUserForm.get('is_bumiputera').hasError('required')">
                      <br>Bumiputra is required
                    </mat-error>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3"
                    *ngIf="this.editUserForm.controls.nationality.value == 'Malaysian'">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                      <mat-label>Race</mat-label>
                      <mat-select formControlName="race_id" required>
                        <ng-container *ngFor="let race of races">
                          <mat-option [value]="race.id" *ngIf="race.is_bumi == this.f.is_bumiputera.value">
                            {{race.name}}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                      <mat-error *ngIf="editUserForm.get('race_id').hasError('required')">
                        Select Race
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                      <mat-label>Phone No.</mat-label>
                      <input matInput formControlName="phone" placeholder="ex: 01212345678" required />
                      <mat-error *ngIf="editUserForm.get('phone').hasError('required')">
                        Phone No. is required
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                      <mat-label>Alternate Phone No.</mat-label>
                      <input matInput formControlName="alt_phone" placeholder="ex: 01212345678" />
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                      <mat-label>Full Address as per IC.</mat-label>
                      <input matInput formControlName="address"
                        placeholder="ex: No.1, Jalan Kemboja, Taman Bunga, 58100 Rawang, Selangor" required />
                      <mat-error *ngIf="editUserForm.get('address').hasError('required')">
                        Full Address is required
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-3">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                      <mat-label>Postcode</mat-label>
                      <input matInput formControlName="postcode" placeholder="ex: 01212345678" placeholder="ex: 12345"
                        mask="00000" />
                      <mat-error *ngIf="editUserForm.get('postcode').hasError('required')">
                        Postcode is required
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-3">
                    <mat-form-field class="example-full-width mb-3" appearance="outline">
                      <mat-label>State</mat-label>
                      <mat-select formControlName="state_id" required>
                        <mat-option [value]=null></mat-option>
                        <mat-option *ngFor="let state of states" [value]="state.id">
                          {{state.name}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="editUserForm.get('state_id').hasError('required')">
                        State is required
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3">
                    <div>
                      <label class="example-margin">Gender</label>
                      <br><br>
                      <mat-radio-group formControlName="gender" class="example-full-width mb-3" required>
                        <mat-radio-button class="example-margin" value="male">
                          Male
                        </mat-radio-button>
                        <mat-radio-button class="example-margin" value="female">
                          Female
                        </mat-radio-button>
                        <mat-error *ngIf="editUserForm.get('gender').hasError('required')">
                          Gender is required
                        </mat-error>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>

                <div class="row" formArrayName="users_roles">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <strong>Roles</strong>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3"
                    *ngFor="let roleCtrl of editUserForm.get('users_roles')['controls']; let r = index"
                    [formGroupName]="r">
                    <input type="hidden" formControlName="id">
                    <mat-checkbox formControlName="is_selected" class="example-margin" [labelPosition]="labelPosition">
                      {{roleCtrl.get('name').value}}
                    </mat-checkbox>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <mat-error *ngIf="!isRoleSelected">Please select Role(s)</mat-error>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Password</mat-label>
                      <input matInput formControlName="password" [type]="hide ? 'password' : 'text'"
                        (input)="onPasswordInput()">
                      <mat-icon matSuffix (click)="hide = !hide">
                        {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                      <mat-error *ngIf="password.hasError('minlength')">Password must have at least {{minPw}} characters
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Confirm Password</mat-label>
                      <input matInput formControlName="cpassword" [type]="hide ? 'password' : 'text'"
                        (input)="onPasswordInput()">
                      <mat-icon matSuffix (click)="hide = !hide">
                        {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                      <mat-error *ngIf="cpassword.invalid && !cpassword.hasError('required')">Passwords don't match
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <button class="mr-3" mat-raised-button color="primary">Update User</button>
                    <button type="button" mat-button>Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
