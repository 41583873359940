import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Subject,throwError } from 'rxjs';
import { Role } from './role.model';
import { tap, map,catchError } from 'rxjs/operators';
import { ConstantsService } from '../services/constants.service';


@Injectable({
  providedIn: 'root'
})

export class RoleService {
  private roles = new Subject<Role> ();
  constructor (
    private http:HttpClient,
    private constantService: ConstantsService) {
  }

  getRoles(){
  	return this.http.get<any>(
  		this.constantService.baseAppUrl + 'roles/getRoles.json',
  	).pipe(
		catchError(this.handleError),
      	tap(res => {
      		return this.handleResponse(res);
      	})
    )
  }

  private handleResponse(res:any){
  	return res.data;
  }

  private handleError(data: HttpErrorResponse) {
    return throwError(data.error.message);
  }
}
