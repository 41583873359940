import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import Swal from 'sweetalert2';
import { SlotsService } from '../slots.service';
import { Subscription } from 'rxjs';
import { TimesService } from '../../times/times.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Slot } from '../slots.model';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },

};

@Component({
  selector: 'app-slot-panel-view',
  templateUrl: './slot-panel-view.component.html',
  styleUrls: ['./slot-panel-view.component.sass'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true } }
  ]
})
export class SlotPanelViewComponent implements OnInit {

  isLoading = true;
  isSaving = false;
  slotId;
  slot: Slot;
  isActive = false;

  getTimesSubscription: Subscription;
  defaultTimes = []
  getSlotSubscription: Subscription;
  slotSubscription: Subscription;


  // checkbox
  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;

  constructor(
    private slotsService: SlotsService,
    private timesService: TimesService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {

    this.route.params.subscribe(
      (params: Params) => this.slotId = params['id']
    );

    this.getSlotSubscription = this.slotsService.getSlot(this.slotId,false).subscribe(() => {
      this.generateSlot();
    })

  }

  private generateSlot() {

    this.slotSubscription = this.slotsService.slotSubject
      .subscribe(
        slot => {
          this.getSlotSubscription.unsubscribe();

          this.slot = slot;

          if (slot.is_active === 1) {
            this.isActive = true;
          }

          this.isLoading = false;


        })
  }



  onViewApp(appId) {
    this.router.navigate(['/applications/' + appId + '/view'])
  }

}
