import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AddComponent } from './add/add.component';
import { SlotBookingComponent } from './slot-booking/slot-booking.component';
import { SlotEditComponent } from './slot-edit/slot-edit.component';
import { SlotListComponent } from './slot-list/slot-list.component';
import { SlotPanelListComponent } from './slot-panel-list/slot-panel-list.component';
import { SlotPanelViewComponent } from './slot-panel-view/slot-panel-view.component';
import { SlotViewComponent } from './slot-view/slot-view.component';
// import { SlotEditComponent } from './slot-edit/slot-edit.component';
// import { SlotListComponent } from './slot-list/slot-list.component';
// import { SlotViewComponent } from './slot-view/slot-view.component';
// import { SlotBookingComponent } from './slot-booking/slot-booking.component';
// import { SlotPanelListComponent } from './slot-panel-list/slot-panel-list.component';
// import { SlotPanelViewComponent } from './slot-panel-view/slot-panel-view.component';


const routes: Routes = [
  {
    path: 'add',
    component: AddComponent
  },
  {
    path: ':id/edit',
    component: SlotEditComponent
  },
  {
    path: ':id/view',
    component: SlotViewComponent,
  },
  {
    path: 'panel/:id/view',
    component: SlotPanelViewComponent
  },
  {
    path: 'list/cipspark',
    component: SlotListComponent
  },
  {
    path: 'list/cipsprint',
    component: SlotListComponent
  },
  {
    path: 'panel/list/cipspark',
    component: SlotPanelListComponent
  },
  {
    path: 'panel/list/cipsprint',
    component: SlotPanelListComponent
  },
  {
    path: 'list/:appID/:appType',
    component: SlotBookingComponent
  },
  {
    path: 'booking/list/:appID/:appType',
    component: SlotBookingComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SlotsRoutingModule { }
