import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {


  readonly baseAppUrl: string = 'https://gmsapi.cradle.com.my/'; // Localhost

  readonly pitchTemplate = {
    cipSpark: this.baseAppUrl + 'docs/pitch_template/cip_spark_pitch_template.pptx',
    cipSprint: this.baseAppUrl + 'docs/pitch_template/cip_sprint_pitch_template.pptx',
  }

  readonly expenditureTemplate = this.baseAppUrl + 'docs/cip_ignite_allowable_expenses_gms.pdf';

  constructor() { }

  wordCount(e) {
    let textInput;
    let wordCount;
    let words;

    textInput = e.srcElement.value;
    wordCount = textInput ? textInput.split(/\s+/) : 0;
    words = wordCount ? wordCount.length : 0;

    if (words >= 150) {
      wordCount.length = 150;
      textInput = wordCount.join(' ')
      e.srcElement.value = textInput;

      wordCount = textInput ? textInput.split(/\s+/) : 0;
      words = wordCount ? wordCount.length : 0;
    }

    return words;
  }

  checkWordCount(e) {
    let textInput;
    let wordCount;
    let words;

    textInput = e;
    wordCount = textInput ? textInput.split(/\s+/) : 0;
    words = wordCount ? wordCount.length : 0;

    return words;
  }
}
