import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../user.service';
import { User } from '../user.model';
import { AuthenticationService } from '../../authentication/authentication.service';
import { Params, ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.sass']
})
export class UserViewComponent implements OnInit, OnDestroy {

  user: User;
  public proSub: Subscription;
  public proDetails = null;
  isLoading = false;
  public userDetails = null;
  userId:string;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.route.params.subscribe(
      (params: Params) => this.userId = params['id']
    );

    this.userService.fetchUser(this.userId).subscribe()
    this.displayUser()
  }

  displayUser() {
    this.proSub = this.userService.userChanged.subscribe(
      pro => {
        this.proDetails = pro;
        this.isLoading = false;
      }
    )
  }

  ngOnDestroy() {
    this.proSub.unsubscribe();
  }

  onEdit(){
    this.router.navigate(['/users/' + this.userId + '/edit'])
  }

}
