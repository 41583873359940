import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import Swal from 'sweetalert2';
import { SlotsService } from '../slots.service';
import { Subscription } from 'rxjs';
import { TimesService } from '../../times/times.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Slot } from '../slots.model';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },

};

@Component({
  selector: 'app-slot-edit',
  templateUrl: './slot-edit.component.html',
  styleUrls: ['./slot-edit.component.sass'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true } }
  ]
})
export class SlotEditComponent implements OnInit {

  isLoading = true;
  isSaving = false;
  slotId;
  slot: Slot;
  slotForm: FormGroup;
  tracksList = new FormArray([]);
  timesList = new FormArray([]);
  usersList = new FormArray([]);
  isActive = false;

  addNewTrackForm: FormGroup;
  getTimesSubscription: Subscription;
  defaultTimes = []
  getSlotSubscription: Subscription;
  slotSubscription: Subscription;


  // checkbox
  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;

  constructor(
    private slotsService: SlotsService,
    private timesService: TimesService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {

    this.route.params.subscribe(
      (params: Params) => this.slotId = params['id']
    );

    this.getSlotSubscription = this.slotsService.getSlot(this.slotId,true).subscribe(() => {
      this.createSlotForm();
    })

  }

  private createSlotForm() {

    this.slotForm = new FormGroup({
      id: new FormControl(null),
      date: new FormControl(null, [Validators.required]),
      batch_no: new FormControl(null, [Validators.required]),
      venue: new FormControl(null, [Validators.required]),
      is_active: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
      slots_users: this.usersList,
      tracks: this.tracksList
    })

    this.slotSubscription = this.slotsService.slotSubject
      .subscribe(
        slot => {
          this.getSlotSubscription.unsubscribe();

          this.slot = slot;

          this.slotForm.get('id').setValue((slot.id) ? slot.id : null)
          this.slotForm.get('date').setValue((slot.date) ? slot.date : null)
          this.slotForm.get('batch_no').setValue((slot.batch_no) ? slot.batch_no : null)
          this.slotForm.get('venue').setValue((slot.venue) ? slot.venue : null)
          this.slotForm.get('type').setValue((slot.type) ? slot.type : null)
          this.slotForm.get('is_active').setValue((slot.is_active) ? slot.is_active : null)

          if (slot.users.length > 0) {
            this.usersList.clear()
            slot.users.forEach(user => {
              this.usersList.push(
                new FormGroup({
                  id: new FormControl(user.id),
                  name: new FormControl(user.name),
                  is_selected: new FormControl(user.is_selected)
                })
              )
            })
          }

          if (slot.is_active === 1) {
            this.isActive = true;
          }

          if (this.slot.tracks.length) {
            this.createTrackForm(this.slot.tracks)
          } else {
            this.creatNewTrackForm()
          }

        })
  }

  private creatNewTrackForm() {

    this.tracksList.clear()
    this.tracksList.push(
      new FormGroup({
        id: new FormControl(null),
        name: new FormControl(null, [Validators.required]),
        times_tracks: this.timesList
      })
    )

    this.timesService.getTimes().subscribe(
      res => {
        this.defaultTimes = res.data;
        if (this.defaultTimes.length) {
          this.creatNewTimeForm()
        }
      }
    );
  }

  private creatNewTimeForm() {
    this.timesList.clear()
    this.defaultTimes.forEach(time => {
      this.timesList.push(
        new FormGroup({
          id: new FormControl(time.id),
          time: new FormControl(time.time),
          time_id: new FormControl(time.time_id),
          is_active: new FormControl(time.is_active),
        })
      )
    })

    this.isLoading = false;
  }

  private createTrackForm(tracks) {
    this.tracksList.clear()
    let newTimeList = []
    let i = 0;
    for (let track of tracks) {
      let timesTracks = track.times_tracks
      newTimeList[i] = new FormArray([]);
      for (let time of timesTracks) {

        newTimeList[i].push(
          new FormGroup({
            id: new FormControl(time.id),
            time: new FormControl(time.time),
            time_id: new FormControl(time.time_id),
            is_active: new FormControl({value:time.is_active,disabled: time.application_id ? true:false}),
          })
        )
      }

      this.tracksList.push(
        new FormGroup({
          id: new FormControl(track.id),
          name: new FormControl(track.name, [Validators.required]),
          times_tracks: newTimeList[i]
        })
      )
      ++i;
    }
    this.isLoading = false;
  }

  onDuplicateTrack(track) {
    const timesTracks = track.times_tracks
    const newTimeList = new FormArray([]);
    for (let time of timesTracks) {
      newTimeList.push(
        new FormGroup({
          id: new FormControl(null),
          time: new FormControl(time.time),
          time_id: new FormControl(time.time_id),
          is_active: new FormControl(time.is_active),
        })
      )
    }
    this.tracksList.push(
      new FormGroup({
        id: new FormControl(null),
        name: new FormControl(track.name + " copy", [Validators.required]),
        times_tracks: newTimeList
      })
    )

  }

  onActivateSlot(slotId) {
    this.isActive = true;
    this.slotForm.get('is_active').setValue(true)
    this.onSubmit()
  }

  onDeActivateSlot(slotId) {
    this.isActive = false;
    this.slotForm.get('is_active').setValue(false)
    this.onSubmit()
  }

  onSubmit() {


    const selectedDate = new Date(this.slotForm.get('date').value);
    const date = moment(selectedDate).format("YYYY-MM-DD");
    this.slotForm.get('date').setValue(date)

    if (this.slotForm.get('is_active').value === true) {
      this.slotForm.get('is_active').setValue(1)
    } else if (this.slotForm.get('is_active').value === false || this.slotForm.get('is_active').value === null) {
      this.slotForm.get('is_active').setValue(0)
    }

    const tracks = this.slotForm.get('tracks').value

    for (const track of tracks) {
      const timesTracks = track.times_tracks
      for (const time of timesTracks) {
        if (time.is_active === true) {
          time.is_active = 1
        } else if (time.is_active === false) {
          time.is_active = 0
        }
      }
    }

    let usersIDs = []
    let slots_users = this.slotForm.get('slots_users').value;
    let data = this.slotForm.value

    slots_users.forEach(user => {
      if (user.is_selected) {
        usersIDs.push(user.id)
      }
    });

    data.users = { _ids: usersIDs }

    if (this.slotForm.valid) {
      this.isSaving = true;
      this.slotsService.edit(this.slotForm.value).subscribe(
        (response: any) => {
          this.isSaving = false;
          //this.success = response.message;
          //this.router.navigate(['/slots/' + response.slot.id + '/edit']);
        },
        errorMessage => {
          this.isSaving = false;
          //this.error = errorMessage;
        }
      )
    }
  }

  onDeleteTrack(slotId, trackId) {
    Swal.fire({
      text: "Are you sure to delete this track?",
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Yes, I'm sure"
    }).then(result => {
      if (result.value) {
        this.isSaving = true;
        this.slotsService.deleteTrack(slotId, trackId).subscribe(
          (response: any) => {
            this.isSaving = false;
          },
          errorMessage => {
            this.isSaving = false;
          }
        )
      }
    });
  }

}
