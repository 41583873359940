import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse,HttpResponse } from '@angular/common/http';
import { Subject,throwError } from 'rxjs';
import { State } from './state.model';
import { tap, map,catchError } from 'rxjs/operators';
import { ConstantsService } from '../services/constants.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {

	constructor(
		private http:HttpClient,
		private constantService: ConstantsService) {

	}

	getStates(){
		return this.http.get<any>(
			this.constantService.baseAppUrl + 'states/getStates.json',
			).pipe(
			catchError(this.handleError),
			tap(res => {
				return this.handleResponse(res);
			})
		)
	}

	private handleResponse(res:any){
		return res.data;
	}

	private handleError(data: HttpErrorResponse) {
		return throwError(data.error.message);
	}
}
