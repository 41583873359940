import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { SlotsService } from '../slots.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },

};

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.sass'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true } }
  ]
})
export class AddComponent implements OnInit {
  isLoading = false;
  addNewSlotForm: FormGroup;

  constructor(
    private slotsService: SlotsService,
    private router: Router) { }

  ngOnInit() {
    this.createSlotForm();
  }

  private createSlotForm() {
    this.addNewSlotForm = new FormGroup({
      date: new FormControl(null, [Validators.required]),
      batch_no: new FormControl(null, [Validators.required]),
      venue: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required])
    })

  }

  onSubmit() {
    const selectedDate = new Date(this.addNewSlotForm.get('date').value);
    const date = moment(selectedDate).format("YYYY-MM-DD");
    this.addNewSlotForm.get('date').setValue(date)

    if (this.addNewSlotForm.valid) {
      this.isLoading = true;
      this.slotsService.add(this.addNewSlotForm.value).subscribe(
        (response: any) => {
          this.isLoading = false;
          //this.success = response.message;
          this.router.navigate(['/slots/'+response.slot.id+'/edit']);
        },
        errorMessage => {
          this.isLoading = false;
          //this.error = errorMessage;
        }
      )
    }
  }

}
