import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { Role } from './core/models/role';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'doctor',
        canActivate: [AuthGuard],
        data: {
          role: Role.Doctor,
        },
        loadChildren: () =>
          import('./doctor/doctor.module').then((m) => m.DoctorModule),
      },
      {
        path: 'patient',
        canActivate: [AuthGuard],
        data: {
          role: Role.Patient,
        },
        loadChildren: () =>
          import('./patient/patient.module').then((m) => m.PatientModule),
      },
      {
        path: 'applicant',
        canActivate: [AuthGuard],
        data: {
          role: Role.Applicant,
        },
        loadChildren: () =>
          import('./applicant/applicant.module').then((m) => m.ApplicantModule),
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'users',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'applications',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./applications/applications.module').then((m) => m.ApplicationsModule),
      },
      {
        path: 'slots',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./slots/slots.module').then((m) => m.SlotsModule),
      },
      {
        path: 'bp',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./bp/bp.module').then((m) => m.BpModule),
      },
      {
        path: 'sv',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./sv/sv.module').then((m) => m.SvModule),
      },
      {
        path: 'imreco',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./imreco/imreco.module').then((m) => m.ImrecoModule),
      },
      {
        path: 'investment-summaries-report',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./investment-summaries-report/investment-summaries-report.module').then((m) => m.InvestmentSummariesReportModule),
      },
      {
        path: 'extra-pages',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./extra-pages/extra-pages.module').then(
            (m) => m.ExtraPagesModule
          ),
      },
      {
        path: 'multilevel',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./multilevel/multilevel.module').then(
            (m) => m.MultilevelModule
          ),
      },
      {
        path: 'rmslots',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./rm/rm.module').then(m => m.RmModule)
      },
      {
        path: 'amslots',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./am/am.module').then(m => m.AmModule)
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
