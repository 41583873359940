<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">Pitch Slots</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="">
                <i class="fas fa-home"></i> Home</a>
            </li>
            <li class="breadcrumb-item active">Pitch Slots</li>
            <!-- <li class="breadcrumb-item active">{{ applicationTitle }}</li> -->
            <li class="breadcrumb-item active">View</li>
            <li class="breadcrumb-item active li-loading" *ngIf="isLoading">
              <div class="loading-spinner">
                <div class="preloader pl-size-xs">
                  <div class="spinner-layer pl-indigo">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
                <p class="preloader-text"> Loading...</p>
              </div>
            </li>
            <li class="breadcrumb-item active li-loading" *ngIf="isSaving">
              <div class="loading-spinner">
                <div class="preloader pl-size-xs">
                  <div class="spinner-layer pl-indigo">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
                <p class="preloader-text"> Saving...</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="row clearfix" *ngIf="!isLoading">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="header">
              <h2>Pitch Slot Details</h2>
            </div>
            <div class="body">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                  <img width="25%" *ngIf="slot.type == 'cipspark'" src="assets/images/logo-cip-spark.png" alt="" />
                  <img width="25%" *ngIf="slot.type == 'cipsprint'" src="assets/images/logo-cip-sprint.png" alt="" />
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
                  <strong>Pitch Slot Date</strong>
                  <br>
                  <p class="text-muted">{{slot.date | date:'dd/MM/y'}}</p>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
                  <strong>Batch No.</strong>
                  <br>
                  <p class="text-muted">{{slot.batch_no}}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                  <strong>Venue</strong>
                  <br>
                  <p class="text-muted">{{slot.venue}}</p>
                </div>
              </div>
            </div>
            <div class="footer mb-3">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                <p class="pull-left">This Pitch Slot is currently <span *ngIf="isActive"
                    class="text-green">Active</span>
                  <span *ngIf="!isActive" class="text-red">Not Active</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="slot">
        <div class="row clearfix" *ngFor="let track of slot.tracks; let i = index">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="card">
              <div class="body">
                <strong>Track name</strong>
                <br>
                <p class="text-muted">{{track.name}}</p>
                <br><br>
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th rowspan="2" width="2%">#</th>
                          <th rowspan="2" width="10%">Time</th>
                          <th rowspan="2" width="30%">Application Title</th>
                          <th rowspan="2" width="28%">Company</th>
                          <th colspan="2" width="10%"style="text-align: center;">Votes</th>
                          <th rowspan="2" width="10%" colspan="2" style="text-align: center;">Result</th>
                          <th rowspan="2" width="10%" style="text-align: center;">Action</th>
                        </tr>
                        <tr>
                          <th style="text-align: center;" width="5%">Yes</th>
                          <th style="text-align: center;" width="5%">No</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let time of track.times_tracks; let j = index;">
                          <tr *ngIf="time.application">
                            <th>{{ +j + 1 }}</th>
                            <td>{{time.time}}</td>
                            <td>{{time.application.title}}</td>
                            <td>{{time.application?.company?.name ?? '-'}}</td>
                            <td style="text-align: center;">{{time.application.vote_yes}}</td>
                            <td style="text-align: center;">{{time.application.vote_no}}</td>
                            <td>
                              {{(time.application.vote_yes > time.application.vote_no) ? "Shortlisted" : "Failed"}}
                            </td>
                            <td align="center">
                              <span *ngIf="time.application.result_sent">
                                <mat-icon [ngStyle]="{'color':'#3f51b5'}">email</mat-icon>
                                <p>{{time.application.date_shortlisted ? (time.application.date_shortlisted | date:'dd/MM/y') :(time.application.date_application_failed | date:'dd/MM/y')}}</p>
                              </span>

                              <mat-checkbox value="{{time.application.id}}" class="example-margin"
                                [labelPosition]="labelPosition"
                                (change)="showOptions($event,time.application.id,time.application.vote_yes > time.application.vote_no)"
                                *ngIf="!time.application.result_sent">
                              </mat-checkbox>
                            </td>
                            <td align="center">
                              <a mat-mini-fab class="btn btn-primary" target="_blank" href="/#/applications/{{time.application.id}}/view"
                                matTooltip="View Application" matTooltipPosition="above">
                                <mat-icon>arrow_forward</mat-icon>
                              </a>
                              <!-- <button mat-mini-fab class="btn btn-primary" (click)="onViewApp(time.application.id)"
                                matTooltip="View Application" matTooltipPosition="above">
                                <mat-icon>arrow_forward</mat-icon>
                              </button> -->
                              <!-- <a mat-mini-fab class="btn btn-primary" target="_blank" href="{{constService.baseAppUrl}}{{time.application.doc_form9.dir}}{{time.application.doc_form9.name}}"
                                matTooltip="Download Form 9" matTooltipPosition="above">
                                <mat-icon>description</mat-icon>
                              </a> -->
                              <a mat-mini-fab class="btn btn-primary" target="_blank" href="{{constService.baseAppUrl}}{{time.application.doc_slide.dir}}{{time.application.doc_slide.name}}"
                                matTooltip="Download Slide" matTooltipPosition="above">
                                <mat-icon>slideshow</mat-icon>
                              </a>
                            </td>
                            <!-- <td align="center">
                              <div class="btn-group">
                                <button mat-raised-button color="primary" data-toggle="dropdown" aria-haspopup="true"
                                  aria-expanded="false">More Action<span class="caret"></span></button>
                                <ul class="dropdown-menu">
                                  <li>
                                    <a (click)="onViewApp(time.application.id)">View Application</a>
                                  </li>
                                  <li role="separator" class="divider"></li>
                                  <li>
                                    <a target="_blank" href="{{constService.baseAppUrl}}{{time.application.doc_form9.dir}}{{time.application.doc_form9.name}}">Download Form 9</a>
                                  </li>
                                  <li>
                                    <a target="_blank" href="{{constService.baseAppUrl}}{{time.application.doc_slide.dir}}{{time.application.doc_slide.name}}">Download Slideshow</a>
                                  </li>
                                </ul>
                              </div>
                            </td> -->
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="footer">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-3">
                    <div class="loading-spinner" *ngIf="isSaving">
                      <div class="preloader pl-size-xs">
                        <div class="spinner-layer pl-indigo">
                          <div class="circle-clipper left">
                            <div class="circle"></div>
                          </div>
                          <div class="circle-clipper right">
                            <div class="circle"></div>
                          </div>
                        </div>
                      </div>
                      <p class="preloader-text"> Saving...</p>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-3">
                    <button mat-raised-button color="primary" class="pull-right mr-3" (click)="onSendResult()">Send
                      Result</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
