import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, ValidatorFn, ValidationErrors } from '@angular/forms';

import { startWith, map } from 'rxjs/operators';

import { Subscription, Observable } from 'rxjs';
import Swal from 'sweetalert2';

import { User } from '../user.model';
import { Race } from '../../shared/race/race.model';
import { State } from '../../state/state.model';
import { Role } from '../../shared/role/role.model';
import { RaceService } from '../../shared/race/race.service';
import { RoleService } from '../../shared/role/role.service';
import { StateService } from '../../shared/state/state.service';
import { UserService } from '../user.service';
import { Country, CountryService } from 'src/app/shared/country/country.service';

export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  if (formGroup.get('password').value === formGroup.get('cpassword').value)
    return null;
  else
    return {passwordMismatch: true};
};

@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html',
  styleUrls: ['./user-new.component.sass']
})



export class UserNewComponent implements OnInit {
  isLoading = true;
  isSaving = false;
  addNewUserForm: FormGroup;
  rolesList = new FormArray([]);
  identityTypeId = null;
  raceId = null;
  user = null;
  success: string = null;
  error: string = null;
  isRoleSelected = true;

  public userSub: Subscription;
  public userDetails = null;
  public proSub: Subscription;

  public races: Race[];
  public countries: Country[];
  filteredRaceOptions: Observable<Race[]>;
  public states: State[];
  getRolesSubscription: Subscription;
  public defaultRoles = [];
  minPw = 8;



  constructor(
    private raceService: RaceService,
    private stateService: StateService,
    private roleService: RoleService,
    private userService: UserService,
    private countryService:CountryService,
    private route: ActivatedRoute,
    private router: Router

  ) { }

  ngOnInit(): void {
    this.createNewUserForm();
  }

  private createNewUserForm() {
    this.addNewUserForm = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      email: new FormControl(null, [Validators.required, Validators.email, Validators.minLength(5)]),
      nationality: new FormControl(null, [Validators.required]),
      identity_type_id: new FormControl(null, [Validators.required]),
      identity_no: new FormControl(null, [Validators.required]),
      race_id: new FormControl(null, [Validators.required]),
      race: new FormControl(null),
      phone: new FormControl(null, [Validators.required]),
      alt_phone: new FormControl(null),
      address: new FormControl(null, [Validators.required]),
      is_bumiputera: new FormControl(null, [Validators.required]),
      country_id: new FormControl(null, [Validators.required]),
      postcode: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      state_id: new FormControl(null, [Validators.required]),
      users_roles: this.rolesList,
      password: new FormControl(null, [Validators.required, Validators.minLength(this.minPw)]),
      cpassword: new FormControl(null, [Validators.required])
    },passwordMatchValidator);

    this.raceService.getRaces().subscribe(
      res => {
        this.races = res.data;
        // this.filteredRaceOptions = this.addNewUserForm.controls.race.valueChanges.pipe(
        //   startWith(''),
        //   map(value => (typeof value === 'string' ? value : value.name)),
        //   map(name => (name ? this._filterRace(name) : this.races.slice()))
        // );
      }
    );

    this.stateService.getStates().subscribe(
      res => {
        this.states = res.data;
      }
    );

    this.countryService.getCountries().subscribe(
      res => {
        this.countries = res.data;
      }
    );

    this.roleService.getRoles().subscribe(
      res => {
        this.defaultRoles = res.data;
        if (this.defaultRoles.length) {
          this.createUsersRolesForm()
        }
      }
    );
  }

  get password() { return this.addNewUserForm.get('password'); }
  get cpassword() { return this.addNewUserForm.get('cpassword'); }
  get f() {
    return this.addNewUserForm.controls;
  }

  onPasswordInput() {
    if (this.addNewUserForm.hasError('passwordMismatch'))
      this.cpassword.setErrors([{'passwordMismatch': true}]);
    else
      this.cpassword.setErrors(null);
  }

  private createUsersRolesForm() {
    this.rolesList.clear()
    this.defaultRoles.forEach(role => {
      this.rolesList.push(
        new FormGroup({
          id: new FormControl(role.id),
          name: new FormControl(role.name),
          is_selected: new FormControl(false),
        })
      )
    })

    this.isLoading = false;
  }

  onIdentityTypeChange(e) {
    this.identityTypeId = e.value;
  }

  onRaceChange(e) {
    this.raceId = e.value;
    const raceOther = this.addNewUserForm.controls.race;
    raceOther.patchValue('');

    if (this.raceId >= 4) {
      raceOther.setValidators([Validators.required])
    } else {
      raceOther.setErrors(null);
    }
  }

  // displayFn(race: Race): string {
  //   return race && race.name ? race.name : '';
  // }

  // private _filterRace(name: string): Race[] {
  //   const filterValue = name.toLowerCase();
  //   return this.races.filter(
  //     option => option.name.toLowerCase().indexOf(filterValue) === 0
  //   );
  // }

  nationaityChange(event) {
    let value = event?.value ?? event;
    console.log(value);
    if (value) {
      if (value == 'Malaysian') {
        this.addNewUserForm.addControl('is_bumiputera', new FormControl(null, [Validators.required]));
        this.addNewUserForm.addControl('race_id', new FormControl(null, [Validators.required]));
        this.addNewUserForm.removeControl('country_id');
      } else if (value == 'Non-Malaysian') {
        this.addNewUserForm.removeControl('is_bumiputera');
        this.addNewUserForm.removeControl('race_id');
        this.addNewUserForm.addControl('country_id', new FormControl(null, [Validators.required]));
      }
    }
  }

  bumiputraChange(event) {
    this.addNewUserForm.controls.race_id.reset();
  }

  onSubmit() {
    console.log(this.addNewUserForm);
    let rolesIDs = []
    let users_roles = this.addNewUserForm.get('users_roles').value;
    let data = this.addNewUserForm.value

    users_roles.forEach(role => {
      if (role.is_selected) {
        rolesIDs.push(role.id)
      }
    });

    data.roles = { _ids: rolesIDs }

    if (rolesIDs.length <= 0) {
      Swal.fire({
        title: 'Uh Oh!!',
        text: "Please Select Role(s).",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        //cancelButtonText: 'No, I am not!',
        confirmButtonText: 'OK'
      })
      this.isRoleSelected = false;
    } else {
      this.isRoleSelected = true;
    }
    if (this.addNewUserForm.valid && this.isRoleSelected) {
      const result = this.userService.add(data);
      result.subscribe(
        res => {
          Swal.fire({
            title: 'Congratulation!',
            text: "Your User has been Registered",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            //cancelButtonText: 'No, I am not!',
            confirmButtonText: 'OK'
          }).then(result => {
            if (result.value) {
              this.router.navigate(['/users/list'])
            }
          });
        },
        errorMessage => {
          Swal.fire({
            title: 'Sorry!',
            text: errorMessage,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            //cancelButtonText: 'No, I am not!',
            confirmButtonText: 'OK'
          }).then(result => {
            // if (result.value) {
            //   this.router.navigate(['/user/details'])
            // }
          });
        }
      )
    }
  }

}
