import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { Router, Params, ActivatedRoute, UrlSegment, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET } from '@angular/router';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { TooltipPosition } from '@angular/material/tooltip';


import { ConstantsService } from '../../shared/services/constants.service';
import { Slot } from '../slots.model';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-slot-panel-list',
  templateUrl: './slot-panel-list.component.html',
  styleUrls: ['./slot-panel-list.component.sass'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }
  ]
})


export class SlotPanelListComponent implements OnInit {
  isLoading = true;
  dtOptions: DataTables.Settings = {};
  slots: Slot[];
  iDisplayLength: number;
  appType;

  constructor(
    private http: HttpClient,
    private constant: ConstantsService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const url = this.router.url;
    const urlTree: UrlTree = this.router.parseUrl(url);
    const urlG: UrlSegmentGroup = urlTree.root.children[PRIMARY_OUTLET];
    const urlS: UrlSegment[] = urlG.segments;

    this.appType = urlS[3].path;


    const that = this;

    this.dtOptions = {
      pageLength: 10,
      serverSide: true,
      processing: true,
      // scrollX: true,
      order: [[2, "desc"]],
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.constant.baseAppUrl + 'slots/panelIndex/' + that.appType + '.json',
            dataTablesParameters, {}
          ).subscribe(resp => {
            that.slots = resp.data;
            that.isLoading = false;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              draw: resp.draw,
              data: []
            });
          });
      },
      columns: [
        {
          data: 'id',
          orderable: false,
        },
        {
          data: 'batch_no'
        },
        {
          data: 'date',
        },
        {
          data: 'is_active',
        }
      ]
    };
  }

  onViewSlot(slotId) {
    this.router.navigate(['/slots/panel/' + slotId + '/view'])
  }

}
