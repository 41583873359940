import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from "src/app/core/service/auth.service";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.sass"],
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string;
  @Input() items: any[];
  @Input() active_item: string;

  homePage: string;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    const userRole = this.authService.currentUserValue.role;

    if (userRole === "Admin") {
      this.homePage = "/admin/dashboard/main";
    } else if (userRole === "Patient") {
      this.homePage = "/patient/dashboard";
    } else if (userRole === "Doctor") {
      this.homePage = "/doctor/dashboard";
    } else if (userRole === "Applicant") {
      this.homePage = "/applicant/dashboard";
    } else {
      this.homePage = "/admin/dashboard/main";
    }
  }
}
