import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Subject,throwError } from 'rxjs';
import { tap, map,catchError } from 'rxjs/operators';


import { ConstantsService } from '../shared/services/constants.service';
import { Times } from './times.model';

@Injectable({
  providedIn: 'root'
})
export class TimesService {

  private races = new Subject<Times> ();

  constructor (
    private http:HttpClient,
    private constantService: ConstantsService) {
  	
  }

  getTimes(){
  	return this.http.get<any>(
  		this.constantService.baseAppUrl + 'tracks/getDefaultTimesTracks.json',
  	).pipe(
		catchError(this.handleError),
      	tap(res => {
      		return this.handleResponse(res);
      	})
    )
  }

  private handleResponse(res:any){
  	return res.data;
  }

  private handleError(data: HttpErrorResponse) {
    return throwError(data.error.message);
  }
}
