<section class="content">
  <div class="content-block">
    <div class="block-header">
      <app-breadcrumb [title]="'User List'" [items]="['Users','User List']" [active_item]="'User List'"></app-breadcrumb>

      <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="card">
            <div class="body">
              <div class="row">
                <div class="col">
                  <table datatable [dtOptions]="dtOptions" class="table" style="width: 100%;">
                    <thead>
                      <tr>
                        <th style="width: 20px;">#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Roles</th>
                        <th style="text-align: center; width: 120px;">Action</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="users?.length != 0">
                      <tr *ngFor="let user of users | keyvalue">
                        <th>{{ +user.key + 1 }}</th>
                        <td>{{user.value.name}}</td>
                        <td>{{user.value.email}}</td>
                        <td>{{user.value.phone}}</td>
                        <td>
                          <ol class="standard-ol" style="padding-left: 0;">
                            <li *ngFor="let role of user.value.roles">{{role.name}}</li>
                          </ol>
                        </td>
                        <td style="text-align: center;">
                          <button mat-mini-fab class="btn btn-primary mr-3" (click)="onEditUser(user.value.id)"
                            matTooltip="Edit User" matTooltipPosition="above">
                            <mat-icon>mode_edit</mat-icon>
                          </button>
                          <button mat-mini-fab class="btn btn-primary" (click)="onViewUser(user.value.id)"
                            matTooltip="View User" matTooltipPosition="above">
                            <mat-icon>arrow_forward</mat-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="users?.length == 0">
                      <tr>
                        <td colspan="3" class="no-data-available">No data!</td>
                      </tr>
                    <tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
