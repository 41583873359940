import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Subject,throwError } from 'rxjs';
import { tap, map,catchError } from 'rxjs/operators';

import { User } from './user.model';
import { stringify } from 'querystring';
import { ConstantsService } from '../shared/services/constants.service';

export interface UserResponseData {
  user: {},
  message: string
}

@Injectable ({providedIn: 'root'})
export class UserService {

  userChanged = new Subject<User>();
  private user: User;

  constructor (
    private http:HttpClient,
    private constantService: ConstantsService) {}

  add(data) {
    return this.http
      .post<UserResponseData>(
        this.constantService.baseAppUrl + 'users/addUser.json',
        data
      ).pipe(
          catchError(this.handleError),
          tap(res => {
            return res;
          })
      );
  }

  fetchUser(id) {
    return this.http
    .get<UserResponseData>(
      this.constantService.baseAppUrl + 'users/getUser/'+id+'.json'
    )
    .pipe(
      tap(res => {
        this.setUser(res.user);
      })
    );
  }

  private setUser(data) {

    this.user = data;
    this.userChanged.next(this.user);
  }

  getUser() {
    return this.user;
  }

  editUser(data,userId) {
    return this.http
      .post<UserResponseData>(
        this.constantService.baseAppUrl + 'users/editUser/'+userId+'.json',
        data
      ).pipe(
          catchError(this.handleError),
          tap(res => {
            return res;
          })
      );
  }



  // getUsers() {
  //   return this.http
  //     .post<UserResponseData>(
  //       this.constantService.baseAppUrl + 'users/add.json',
  //       data
  //     ).pipe(
  //         catchError(this.handleError),
  //         tap(res => {
  //           return res;
  //         })
  //     );
  // }

  // fetch(id) {
  //   return this.http
  //   .get<UserResponseData>(
  //     this.constantService.baseAppUrl + 'users/view/'+id+'.json'
  //   )
  //   .pipe(
  //     tap(res => {
  //       this.setUser(res.user);
  //     })
  //   );
  // }

  // private setUser(data) {

  //   this.user = data;
  //   this.userChanged.next(this.user);
  // }

  // getUser() {
  //   return this.user;
  // }

  private handleError(data: HttpErrorResponse) {
    return throwError(data);
  }

  // updateIsComplete(user) {
  //   const userData: {
  //     id: string;
  //     email: string;
  //     roles: object,
  //     is_complete: number,
  //     _token: string;
  //     _tokenExpirationDate: string;
  //   } = JSON.parse(localStorage.getItem('userData'));

  //   userData.is_complete = user.is_complete;
  //   localStorage.setItem('userData', JSON.stringify(userData));
  // }

}
