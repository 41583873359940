import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { Router, Params, ActivatedRoute, UrlSegment, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET } from '@angular/router';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { TooltipPosition } from '@angular/material/tooltip';
import Swal from 'sweetalert2';


import { ConstantsService } from '../../shared/services/constants.service';
import { Slot } from '../slots.model';
import { SlotsService } from '../slots.service';
import { Subscription } from 'rxjs';
import { Application, Applicant } from '../../applications/application.model';
import { ApplicationService } from '../../applications/application.service';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-slot-booking',
  templateUrl: './slot-booking.component.html',
  styleUrls: ['./slot-booking.component.sass'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }
  ]
})
export class SlotBookingComponent implements OnInit {
  isLoading = true;
  isSaving = false;
  dtOptions: DataTables.Settings = {};
  slots: Slot[];
  iDisplayLength: number;
  appID;
  appType;
  isViewSlots = false;
  getAvailableSlotSubscription: Subscription;
  availableSlotSubscription: Subscription;
  availableSlots;
  selectedSlot;
  getBookedSlotSubscripion: Subscription;
  bookedSlotSubscription: Subscription;
  bookedSlot;

  fetchAppSubscription: Subscription;
  appSubscription: Subscription;
  application: Application;

  constructor(
    private http: HttpClient,
    private constant: ConstantsService,
    private router: Router,
    private route: ActivatedRoute,
    private slotsService: SlotsService,
    private applicationService: ApplicationService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params: Params) => this.appID = params['appID']
    );

    this.route.params.subscribe(
      (params: Params) => this.appType = params['appType']
    );

    this.getBookedSlotSubscripion = this.slotsService.getBookedSlotInfo(this.appID).subscribe(() => {
      this.bookedSlotSubscription = this.slotsService.bookedSlotSubject.subscribe(
        slot => {
          this.bookedSlot = slot[0];
        }
      )
    })

    this.fetchAppSubscription = this.applicationService.fetch(this.appID).subscribe(() => {
      this.appSubscription = this.applicationService.appSubject.subscribe(
        app => {
          this.application = app;
        }
      )
    })

    const that = this;

    this.dtOptions = {
      pageLength: 10,
      serverSide: true,
      processing: true,
      // scrollX: true,
      order: [[2, "desc"]],
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.constant.baseAppUrl + 'slots/getAvailableSlots/' + that.appID + '/' + that.appType + '.json',
            dataTablesParameters, {}
          ).subscribe(resp => {
            that.slots = resp.data;
            that.isLoading = false;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              draw: resp.draw,
              data: []
            });
          });
      },
      columns: [
        {
          data: 'id',
          orderable: false,
        },
        {
          data: 'batch_no'
        },
        {
          data: 'date',
        },
        {
          data: 'is_active',
        }
      ]
    };
  }

  onViewSlots(appID, slotID) {
    this.isLoading = true;
    this.viewSlots(slotID)
  }

  viewSlots(slotID) {

    this.getAvailableSlotSubscription = this.slotsService.getAvailableSlot(slotID).subscribe(() => {
      this.createSlotForm();
    })

  }

  createSlotForm() {

    this.availableSlotSubscription = this.slotsService.availableSlotSubject
      .subscribe(
        slot => {
          this.getAvailableSlotSubscription.unsubscribe();

          this.availableSlots = slot;

          this.isLoading = false;
          this.isViewSlots = true;
        }
      )

  }

  updateSlotTime(timeTrackId) {
    this.selectedSlot = timeTrackId;
  }

  bookSlot() {
    if (!this.selectedSlot) {
      Swal.fire({
        text: "Please select Slot",
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    } else {
      if (this.bookedSlot) {
        Swal.fire({
          text: "Are you sure to change your Pitch Slot?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Yes, I'm sure"
        }).then(result => {
          if (result.value) {
            this.proceedBookSlot()
          }
        });
      } else {
        this.proceedBookSlot()
      }
    }

  }

  proceedBookSlot() {
    this.slotsService.bookSlot(this.availableSlots.id, this.selectedSlot, this.appID).subscribe(
      (response: any) => {
        if (response.status === true) {
          const content = "You have booked your Pitch Slot.<br/>The deadline for Pitch Slot Booking is 1 day before the Pitching Date. No changes can be done after the deadline."
          Swal.fire({
            // text: "You have booked your Pitch Slot.",
            title: 'Congratulations!',
            html: content,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          }).then(result => {
            this.router.navigate(['/applications/list']);
          });
        } else {
          Swal.fire({
            text: "Sorry, the slot you have selected has been taken.",
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          }).then(result => {
            this.availableSlots = response.slot;
          });
        }
        this.isSaving = false;
      },
      errorMessage => {
        this.isSaving = false;
      }
    )
  }

}
