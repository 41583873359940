
<section class="content">
  <div class="content-block">
    <div class="block-header">
      <div class="row clearfix">
        <app-breadcrumb [title]="'Add Slot'" [items]="['Slots','Add Slot']" [active_item]="'Add Slot'"></app-breadcrumb>
      </div>
      <div class="row clearfix">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="body">
              <form class="m-4" [formGroup]="addNewSlotForm" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 mb-3">
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
                        <mat-form-field class="example-full-width mb-3" appearance="outline">
                          <mat-label>Pitch Slot Date</mat-label>
                          <input matInput [matDatepicker]="date" (click)="date.open()" required formControlName="date"
                            placeholder="DD/MM/YYYY">
                          <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                          <mat-datepicker #date></mat-datepicker>
                          <mat-error *ngIf="addNewSlotForm.get('date').hasError('required')">
                            Pitch Slot Date is required
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
                        <mat-form-field class="example-full-width mb-3" appearance="outline">
                          <mat-label>Batch No.</mat-label>
                          <input matInput formControlName="batch_no" autocomplete="off" required>
                          <mat-error *ngIf="addNewSlotForm.get('batch_no').hasError('required')">
                            Batch No. is required
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                        <mat-form-field class="example-full-width mb-3" appearance="outline">
                          <mat-label>Venue</mat-label>
                          <input matInput formControlName="venue" autocomplete="off" required>
                          <mat-error *ngIf="addNewSlotForm.get('venue').hasError('required')">
                            Venue is required
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3">
                    <div>
                      <label class="example-margin">Application type<span style="color: #f44336;"> *</span></label>
                      <br><br>
                      <mat-radio-group class="example-full-width mb-3" formControlName="type" required>
                        <mat-radio-button class="example-margin" value="cipspark"><img style="width: 80px;"
                            src="assets/images/logo-cip-spark.png" alt="" /></mat-radio-button>
                        <mat-radio-button class="example-margin" value="cipsprint"><img style="width: 80px;"
                            src="assets/images/logo-cip-sprint.png" alt="" /></mat-radio-button>
                      </mat-radio-group>
                      <br><br>
                      <mat-error *ngIf="addNewSlotForm.get('type').hasError('required')">
                        <small>Application type is required</small>
                      </mat-error>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <button type="submit" class="mr-3" mat-raised-button color="primary">Save & Continue</button>
                    <button type="button" mat-button>Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
