<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">Pitch Slots</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="">
                <i class="fas fa-home"></i> Home</a>
            </li>
            <li class="breadcrumb-item bcrumb-2">
              <a href="/pitchslots/list">Pitch Slots</a>
            </li>
            <!-- <li class="breadcrumb-item active">{{ applicationTitle }}</li> -->
            <li class="breadcrumb-item active">Edit</li>
            <li class="breadcrumb-item active li-loading" *ngIf="isLoading">
              <div class="loading-spinner">
                <div class="preloader pl-size-xs">
                  <div class="spinner-layer pl-indigo">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
                <p class="preloader-text"> Loading...</p>
              </div>
            </li>
            <li class="breadcrumb-item active li-loading" *ngIf="isSaving">
              <div class="loading-spinner">
                <div class="preloader pl-size-xs">
                  <div class="spinner-layer pl-indigo">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
                <p class="preloader-text"> Saving...</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <form class="m-4" [formGroup]="slotForm" (ngSubmit)="onSubmit()" *ngIf="!isLoading">
        <div class="row clearfix">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="card">
              <div class="header">
                <h2>Pitch Slot Details</h2>
              </div>
              <div class="body">
                <input type="hidden" formControlName="id">
                <input type="hidden" formControlName="is_active">
                <div class="row">
                  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 mb-3">
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">

                        <mat-form-field class="example-full-width mb-3" appearance="outline">
                          <mat-label>Pitch Slot Date</mat-label>
                          <input matInput [matDatepicker]="date" (click)="date.open()" required formControlName="date"
                            placeholder="DD/MM/YYYY">
                          <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                          <mat-datepicker #date></mat-datepicker>
                          <mat-error *ngIf="slotForm.get('date').hasError('required')">
                            Pitch Slot Date is required
                          </mat-error>
                        </mat-form-field>

                      </div>

                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
                        <mat-form-field class="example-full-width mb-3" appearance="outline">
                          <mat-label>Batch No.</mat-label>
                          <input matInput formControlName="batch_no" autocomplete="off" required>
                          <mat-error *ngIf="slotForm.get('batch_no').hasError('required')">
                            Batch No. is required
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                        <mat-form-field class="example-full-width mb-3" appearance="outline">
                          <mat-label>Venue</mat-label>
                          <input matInput formControlName="venue" autocomplete="off" required>
                          <mat-error *ngIf="slotForm.get('venue').hasError('required')">
                            Venue is required
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3">
                    <div>
                      <label class="example-margin">Application type</label>
                      <br><br>
                      <mat-radio-group formControlName="type" required>
                        <mat-radio-button class="example-margin" value="cipspark"><img style="width: 80px;"
                            src="assets/images/logo-cip-spark.png" alt="" /></mat-radio-button>
                        <mat-radio-button class="example-margin" value="cipsprint"><img style="width: 80px;"
                            src="assets/images/logo-cip-sprint.png" alt="" /></mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
                <div class="row" formArrayName="slots_users">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <strong>Pitch Panel</strong>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3"
                    *ngFor="let userCtrl of slotForm.get('slots_users')['controls']; let r = index" [formGroupName]="r">
                    <input type="hidden" formControlName="id">
                    <mat-checkbox formControlName="is_selected" class="example-margin" [labelPosition]="labelPosition">
                      {{userCtrl.get('name').value}}
                    </mat-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <p class="pull-left">This Pitch Slot is currently <span *ngIf="isActive"
                        class="text-green">Active</span>
                      <span *ngIf="!isActive" class="text-red">Not Active</span>
                    </p>
                    <button *ngIf="!isActive" class="pull-right btn btn-primary"
                      (click)="onActivateSlot(slotForm.get('id').value)">Activate This Slot</button>
                    <button *ngIf="isActive" class="pull-right btn btn-danger"
                      (click)="onDeActivateSlot(slotForm.get('id').value)">Deactivate This Slot</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <div class="loading-spinner" *ngIf="isSaving">
              <div class="preloader pl-size-xs">
                <div class="spinner-layer pl-indigo">
                  <div class="circle-clipper left">
                    <div class="circle"></div>
                  </div>
                  <div class="circle-clipper right">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
              <p class="preloader-text"> Saving...</p>
            </div>
            <button class="pull-right" type="submit" mat-raised-button color="primary">Update Slot</button>
          </div>
        </div>

        <div formArrayName="tracks">
          <div class="row clearfix" *ngFor="let trackCtrl of slotForm.get('tracks')['controls']; let i = index"
            [formGroupName]="i">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="card">
                <div class="body">
                  <input type="hidden" formControlName="id">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Track name</mat-label>
                    <input matInput formControlName="name" autocomplete="off" required>
                    <mat-error *ngIf="trackCtrl.get('name').hasError('required')">
                      Track name is required
                    </mat-error>
                  </mat-form-field>

                  <div class="row" formArrayName="times_tracks">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <strong>Time Slots</strong>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3"
                      *ngFor="let timesCtrl of trackCtrl.get('times_tracks')['controls']; let j = index"
                      [formGroupName]="j">
                      <input type="hidden" formControlName="id">
                      <input type="hidden" formControlName="time_id">

                      <mat-checkbox formControlName="is_active" class="example-margin" [(indeterminate)]="indeterminate" [labelPosition]="labelPosition">{{timesCtrl.get('time').value}}
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
                <div class="footer">
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                      <button type="button" class="btn btn-primary pull-right mr-3" mat-raised-button
                        (click)="onDuplicateTrack(trackCtrl.value)">Duplicate this Track</button>
                      <button type="button" class="btn btn-red pull-right mr-3" mat-raised-button
                        (click)="onDeleteTrack(slotForm.get('id').value,trackCtrl.get('id').value)"
                        *ngIf="slot.tracks.length > 0">Delete this Track</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
              <div class="loading-spinner" *ngIf="isSaving">
                <div class="preloader pl-size-xs">
                  <div class="spinner-layer pl-indigo">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
                <p class="preloader-text"> Saving...</p>
              </div>
              <button class="pull-right" type="submit" mat-raised-button color="primary">Update
                Slot</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
