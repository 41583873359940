import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { GetUser } from '../user.model';
import { ConstantsService } from '../../shared/services/constants.service';
import { HttpClient } from '@angular/common/http';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import {TooltipPosition} from '@angular/material/tooltip';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.sass']
})
export class UserListComponent implements OnInit {

  isLoading = true;
  dtOptions: DataTables.Settings = {};
  users: GetUser[];
  iDisplayLength: number;
  appType;

  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];

  constructor(
    private http: HttpClient,
    private constant: ConstantsService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {

    const that = this;

    this.dtOptions = {
      pageLength: 10,
      serverSide: true,
      processing: true,
      // scrollX: true,
      order: [[1, "desc"]],
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            this.constant.baseAppUrl + 'users/getUsers.json',
            dataTablesParameters, {}
          ).subscribe(resp => {
            that.users = resp.data;
            that.isLoading = false;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              draw: resp.draw,
              data: []
            });
          });
      },
      columns: [
        {
          data: 'id',
          orderable: false,
        },
        {
          data: 'name'
        },
        {
          data: 'email',
        },
        {
          data: 'phone',
        },
        {
          data: 'roles',
        }
      ]
    };
  }

  onEditUser(userId) {
    this.router.navigate(['/users/' + userId + '/edit'])
  }

  onViewUser(userId) {
    this.router.navigate(['/users/' + userId + '/view'])
  }

}
